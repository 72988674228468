import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
// import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
// import { visuallyHidden } from '@mui/utils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from '../../redux/slices/users';
import { Link } from 'react-router-dom';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import KeyIcon from '@mui/icons-material/Key';
import { Typography } from '@mui/material';
import axios from '../../axios';
import BasicTabs from '../../components/Tabs/BasicTabs';
import styles from './Users.module.scss';
import { checkRole } from '../../redux/slices/auth';

export const UsersTable = ({ data, role, isActive })=>{
    const manager = useSelector(checkRole);

    const suspend = async(id, active, car)=>{
      if (car){
        return alert(`Водій не здав авто.`)
      }

      let status = true;
      if(active===true){
        status=false;
      }
      try {
        await axios.put(`/manage/suspend-user/${id}?active=${status}`);
        window.location.reload(false);
      } catch (err) {
        console.error(err);
        alert('не вдалося змінити статус');
      }
    }
  
    const openModal = ()=>{
      alert('Ця функція поки недоступна, додамо в наступному оновленні')
    }

    const Rows = ({data}) => {
      return (
        data ? 
          data.map((item, index) => (
            item.role===role && item.active===isActive? 
              <TableRow
                hover
                key={index}
                sx={{ cursor: 'pointer'}}
                className={`table-row table-row--${item.active?'active':'not-active'}`}
              >
                <TableCell className='inner' align="left">{item.fName}</TableCell>
                <TableCell className='inner' align="left">{item.sName}</TableCell>
                <TableCell className='inner' align="left">{item.email}</TableCell>
                <TableCell className='inner' align="left">{item.car===null?'без авто':item.car.carNumber}</TableCell>
                <TableCell className='inner' align="left">{item.company}</TableCell>   
                <TableCell className='inner' align="left">
                  <Button 
                    onClick={openModal} 
                    color='primary' 
                    aria-label='pass' 
                    startIcon={<KeyIcon />}
                    disabled={manager==='mechanic'}
                  >
                    змінити пароль
                  </Button>
                </TableCell>
                <TableCell className='inner' align="left">
                  <Button 
                    onClick={()=>suspend(item._id, item.active, item.car)}  
                    startIcon={item.active?<DoNotDisturbAltIcon color='error'/>:<RefreshIcon color='primary' />} 
                    aria-label='suspend'
                    disabled={manager==='mechanic'}
                  >
                    {item.active?'призупинити':'активувати'}
                  </Button>
                </TableCell>   
              </TableRow>
            : null
          ))
        : []
      );
    }
       
    return (
        <Paper sx={{ width: '100%', mb: 2, mt:1 }}>
          <TableContainer>
            <Table
              // sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={'medium'}
            > 
              <TableBody>
                <Rows data={data}/>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
    );
}

export const Users = ()=>{

  const dispatch = useDispatch();
  const {data: users}= useSelector(state=>state.users);  

  React.useEffect(()=>{    
      dispatch(fetchUsers());
  },[])

  return(
    <>
    <Box  className='main-container' textAlign='right'>
      <Box sx={{display:'flex', justifyContent:'space-between'}}>
      <Typography variant='h2' classes={{root:styles.title}} >Список водіїв</Typography> 
      <Link  to='/cabinet/add-new-user' style={{ textDecoration: 'none' }} m={0}>
        <Button  variant="contained" color='primary' >
          Додати водія
        </Button>
      </Link>
      </Box>
    </Box>
    <BasicTabs 
          title1='Активні'
          tab1={<UsersTable data={users} isActive={true} role='driver'/>} 
          title2='Неактивні' 
          tab2={<UsersTable data={users} isActive={false} role='driver'/>} 
        />
    </>
  )
}