import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { ImagePreview } from "../../../utils/ImagePreview";
import styles from './Steps.module.scss';
import { CarlogFields } from "../carlogComponents/CarLogFields";

export const Step3 = ({step, handleChangeImageUrl})=>{
  return (
    <> 
        <Box mb={3}>
          <Typography                 
          variant="h5"
          classes={{root: styles.title}}
          >
          {`0${step}. Технічний стан`}
          </Typography>
          <Typography variant="p"
          classes={{root: styles.subtitle}}
          >
          Зробіть фото показників панелі
          </Typography> 
        </Box>
        <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} sx={{justifyContent:'center'}}>
        {CarlogFields.slice(5, 8).map((field, index) => (
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3} key={field.name}>  
            <ImagePreview 
              key={field.name}
              inputName={field.name}
              labelText={field.uName}                         
              onChangeImageUrl={(url) => handleChangeImageUrl(url, field.name)}
              />
             </Grid>

        ))}
        </Grid>
      </Box>
    </>
  );
}
