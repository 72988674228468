import React, { useState,useEffect } from "react";
import { Box, Button, Typography, Grid } from '@mui/material';

import 'easymde/dist/easymde.min.css';
import styles from '../../components/Carlog/Log.module.scss';
import { logout} from '../../redux/slices/auth';
import { Navigate } from "react-router";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectCar, selectCompany, checkRole, checkCar } from '../../redux/slices/auth';
import { Step1, Step2, Step3, Step4, TrailerStep4, Step5, Step6} from '../../components/Carlog/steps';
import axios from '../../axios';
import { FormProvider } from "../../components/Carlog/carlogComponents/FormContext";


 
export const DropCar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const company = useSelector(selectCompany);
  const car = useSelector(selectCar);
  const role = useSelector(checkRole);
  const  {brand, carNumber, _id } = React.useState(car);

  let stepsNumber;
  if(company[0]!=='Автовози'){
    stepsNumber=6;
  }else{
    stepsNumber=7;
  }

  const [activeStep, setActiveStep] = useState(4);
  const [isLoading, setLoading] = useState(false);
  const [frontPhoto, setFrontPhoto] = useState('');
  const [rightPhoto, setRightPhoto] = useState('');
  const [leftPhoto, setLeftPhoto] = useState('');
  const [backPhoto, setBackPhoto] = useState('');

  const [salonPhotos, setSalonPhotos] = useState([]);
  const [lookRating, setLookRating] = useState('');
  const [lookNote, setLookNote] = useState('');

  const [panelPhoto, setPanelPhoto] = useState('');
  const [oilPhoto, setOilPhoto] = useState('');
  const [breaksPhoto, setBreaksPhoto] = useState('');


  const [frontTyreRating, setFrontTyreRating] = useState('');
  const [backTyreRating, setBackTyreRating] = useState('');
  const [tyreDefects, setTyreDefects] = useState([]);
  const [outerDefects, setOuterDefects] = useState([]);
  const [tNote, setTNote] = useState('');
  const [zapasNumber, setZapasNumber] = useState('');
  const [zapasPhotos, setZapasPhotos] = useState([]);

  const [fuelCard_1, setFuelCard_1] = useState(false);
  const [fuelCard_2, setFuelCard_2] = useState(false);
  const [techPasport_1, setTechPasport_1] = useState(false);
  const [techPasport_2, setTechPasport_2] = useState(false);
  const [insurance_1, setInsurance_1] = useState(false);
  const [insurance_2, setInsurance_2] = useState(false);
  const [licence_1, setLicence_1] = useState(false);
  const [licence_2, setLicence_2] = useState(false);
  const [sign, setSign] = useState(false);

  const [tool_1, setTool_1] = useState(false);
  const [tool_2, setTool_2] = useState(false);
  const [tool_3, setTool_3] = useState(false);
  const [tool_4, setTool_4] = useState(false);
  const [tool_5, setTool_5] = useState(false);
  const [tool_6, setTool_6] = useState(false);
  const [tool_7, setTool_7] = useState(false);
  const [tool_8, setTool_8] = useState(false);
  const [tool_9, setTool_9] = useState(false);

  const [beltNumber, setBeltNumber] = useState('');


  const [isNextButtonDisabled, setNextButtonDisabled] = React.useState(true);
  const isStepValid = () => {
    //  return true;
    if (activeStep === 0) {
      return frontPhoto && rightPhoto && leftPhoto && backPhoto;
    } else if (activeStep === 1) {
      return salonPhotos.length > 0 && lookRating;
    } else if (activeStep === 2) {
      return panelPhoto && oilPhoto && breaksPhoto;
    } else if (activeStep === 3) {
      return backTyreRating && frontTyreRating;
    } else if (activeStep === 4) {
      return true;
    } else if (activeStep === 5) {
      return (company[0]!=='Автовози'?true:beltNumber);
    } else if (activeStep === 6) {
      return (company[0]!=='Автовози'?true:(zapasNumber==='0')? true: (zapasPhotos.length>0?true:false));
    }
  };


  const handleChangeImageUrl = (url, inputName) => {
    switch (inputName) {
      case 'front':
        setFrontPhoto(url);
        break;
      case 'right':
        setRightPhoto(url);
        break;
      case 'left':
        setLeftPhoto(url);
        break;
      case 'back':
        setBackPhoto(url);
        break;
      case 'panel':
        setPanelPhoto(url);
        break;
      case 'oil':
        setOilPhoto(url);
        break;
      case 'breaks':
        setBreaksPhoto(url);
        break;

      default:
        break;
    }

  };

  const handleChangeImagesUrls = (urls, inputName) => {
  switch (inputName) {
    case 'salon':
      setSalonPhotos(urls);
      break;
    case 'tyreDefects':
      setTyreDefects(urls);
      break;
    case 'outerDefects':
      setOuterDefects(urls);
      break;
    case 'zapasPhotos':
      setZapasPhotos(urls);
      break;
    default:
      break;
  }
  }
  const handleChangeCheckbox = (event, checkboxName) => {
    switch (checkboxName) {
      case 'fuelCard_1':
        setFuelCard_1(event.target.checked);
        break;
      case 'fuelCard_2':
        setFuelCard_2(event.target.checked);
        break;
      case 'techPasport_1':
        setTechPasport_1(event.target.checked);
        break;
      case 'techPasport_2':
        setTechPasport_2(event.target.checked);
        break;
      case 'insurance_1':
        setInsurance_1(event.target.checked);
        break;
      case 'insurance_2':
        setInsurance_2(event.target.checked);
        break;
      case 'licence_1':
        setLicence_1(event.target.checked);
        break;
      case 'licence_2':
        setLicence_2(event.target.checked);
        break;
      case 'sign':
        setSign(event.target.checked);
        break;
      case 'tool_1':
        setTool_1(event.target.checked);
        break;
      case 'tool_2':
        setTool_2(event.target.checked);
        break;      
      case 'tool_3':
        setTool_3(event.target.checked);
        break;
      case 'tool_4':
        setTool_4(event.target.checked);
        break;  
      case 'tool_5':
        setTool_5(event.target.checked);
        break;
      case 'tool_6':
        setTool_6(event.target.checked);
        break;
      case 'tool_7':
        setTool_7(event.target.checked);
        break;      
      case 'tool_8':
        setTool_8(event.target.checked);
        break;
      case 'tool_9':
        setTool_9(event.target.checked);
        break;  
      default:
      break;
    }
  };

  const steps = [

    {
      component: <Step1 
                  handleChangeImageUrl={handleChangeImageUrl}
                  step={activeStep+1}
                  />,
    },
    {
      component: <Step2 
                  handleChangeImagesUrls={handleChangeImagesUrls}
                  lookNote={lookNote} 
                  setLookNote={setLookNote}
                  lookRating={lookRating}
                  setLookRating={setLookRating}
                  step={activeStep+1}
                />,
    },
    {
      component: <Step3 
                  handleChangeImageUrl={handleChangeImageUrl}
                  step={activeStep+1}
      />,
    },    
    {
      component: <Step4 
                handleChangeImagesUrls={handleChangeImagesUrls}
                frontTyreRating={frontTyreRating}
                setFrontTyreRating={setFrontTyreRating}
                backTyreRatingng={backTyreRating}
                setBackTyreRating={setBackTyreRating}
  
                tyreDefects={tyreDefects}
                setTyreDefects={setTyreDefects}
                outerDefects={outerDefects}
                setOuterDefects={setOuterDefects}
  
                tNote={tNote} 
                setTNote={setTNote}
                step={activeStep+1}
                />,
    },
    {
      component: <Step5 
                  handleChangeCheckbox={handleChangeCheckbox}
                  company={company}
                  step={activeStep+1}   
                  />,
    },
    {
      component: <Step6 
                  handleChangeCheckbox={handleChangeCheckbox}
                  company={company}
                  setBeltNumber={setBeltNumber}
                  step={activeStep+1}  
                  />,
    },
    {
      component: <TrailerStep4 
                  handleChangeImagesUrls={handleChangeImagesUrls}
                  zapasNumber={zapasNumber}
                  setZapasNumber={setZapasNumber}
                  step={activeStep+1}
                  />,
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }; 
  const handleFinish = () =>{
    dispatch(logout());      
    window.localStorage.removeItem('token');
    navigate("/");
  }

  useEffect(() => {
    setNextButtonDisabled(!isStepValid());
  }, [
    activeStep,
    frontPhoto,
    rightPhoto,
    leftPhoto,
    backPhoto,
    salonPhotos,
    lookRating,
    panelPhoto,
    oilPhoto,
    breaksPhoto,
    backTyreRating, 
    frontTyreRating, 
    zapasNumber,
    zapasPhotos, 
    beltNumber,
  ]);

  const hasCar = useSelector(checkCar);

  if (!hasCar||role!=='driver'){
    return <Navigate to="/home" />  
  }

  const outLook = {
    front:frontPhoto,
    right:rightPhoto,
    left:leftPhoto,
    back:backPhoto,
    salon:salonPhotos,
    lookNote:lookNote,
    lookRating:lookRating,
  };
  const panelData = {
    panel: panelPhoto,
    oil: oilPhoto,
    breaks: breaksPhoto,
  }
  const tyreConditions ={
    bTyreRating:backTyreRating,
    fTyreRating:frontTyreRating,
    tyreDefects:tyreDefects,
    outerDefects:outerDefects,
    tNote:tNote,
  }
  const tyreAdd ={
    zapasNumber:zapasNumber,
    zapasPhotos:zapasPhotos,
  }
  const defaultDocs ={
    fuelCard_1: Boolean(fuelCard_1),
    fuelCard_2: Boolean(fuelCard_2),
    techPasport_1: Boolean(techPasport_1),
    licence_1: Boolean(licence_1),
    sign: Boolean(sign),
  }
  const altDocs = {
    fuelCard_1: Boolean(fuelCard_1),
    techPasport_1: Boolean(techPasport_1),
    techPasport_2: Boolean(techPasport_2),
    insurance_1: Boolean(insurance_1),
    insurance_2: Boolean(insurance_2),
    licence_1: Boolean(licence_1),
  }
  const inventory ={
    tool_1: Boolean(tool_1),
    tool_2: Boolean(tool_2),
    tool_3: Boolean(tool_3),
    tool_4: Boolean(tool_4),
    tool_5: Boolean(tool_5),
  }
  const inventoryAdd = {
    tool_6: Boolean(tool_6),
    tool_7: Boolean(tool_7),
    tool_8: Boolean(tool_8),
    tool_9: Boolean(tool_9),
    item_2: beltNumber,  
  }


  let fields;
  if(company[0]==='Автовози'){
    fields = Object.assign({}, outLook, panelData, tyreConditions, tyreAdd, altDocs, inventory, inventoryAdd);
  }else if(company[0]==='Marshal UA'){
    fields = Object.assign({}, outLook, panelData, tyreConditions, altDocs, inventory);
  }else{
    //Marshal Trans
    fields = Object.assign({}, outLook, panelData, tyreConditions, defaultDocs, inventory);
  }


  async function onSubmit() {
    try {
      setLoading(true);

      const { data } = await axios.post('/carlog-drop', fields);
      console.log(data);

      alert('авто здали');
      setLoading(false);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } catch (err) {
      console.error(err);
      alert('не вдалося здати авто', err);
      setLoading(false);
    }
  }




  return (
    <FormProvider>
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={10} md={10} lg={8} xl={8} className='main-container'>   
      <div>
      {activeStep === stepsNumber ? (
        <React.Fragment>
          <Box className='text-container'>
              <Typography classes={{root: styles.title}} variant="h2">Вітаю, ви успішно здали</Typography>
              <Typography classes={{root: styles.subtitle}} variant="p">{car.brand+' '+car.carNumber}</Typography>
            </Box>
          <Button  onClick={handleFinish} size='large' variant="contained" aria-label='повернутися'>Перезайти</Button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box mb={7} >      
            <Typography classes={{root: styles.title}} variant="h2">Перевірка стану авто</Typography>
            <Typography classes={{root: styles.subtitle}} variant="p">{car.brand+' '+car.carNumber}</Typography>        
          </Box> 
          {steps[activeStep].component}
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-evenly', pt: 2, marginTop:'50px'}}>
            <Button
              variant="outlined" size="large"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
              aria-label="назад"
            >
              Назад
            </Button>
            {activeStep=== stepsNumber - 1 ?(
                  <Button onClick={onSubmit} 
                    variant="contained" 
                    size="large" 
                    disabled={isNextButtonDisabled}
                    aria-label="далі"
                  >
                    {isLoading ? 'Зачекайте...' : 'Закінчити'}
                  </Button>
                ):(
                  <Button 
                    className="button" 
                    onClick={handleNext} 
                    variant="contained" 
                    size="large" 
                    disabled={isNextButtonDisabled}
                    aria-label="далі"
                  >
                    Далі
                  </Button>
            )}
          </Box>
        </React.Fragment>
      )}
    </div>
    </Grid>
    </Grid>
    </FormProvider>
  );

};

