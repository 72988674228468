import React, { useEffect, useState } from 'react';
import { Grid, Box, Paper, Button, }from '@mui/material';
import styles from './Post.module.scss';
import axios from '../../axios';
import { PhotoGalery } from '../../components'
import { CommentsBlock } from '../CommentsBlock';
import { TitleCard, Statuses, Urgency } from './components';
import { useSelector } from 'react-redux';
import { checkRole } from '../../redux/slices/auth';


function refreshPage() {
  window.location.reload(false);
}


export const Post = ({
  id,
  text,
  problem,
  urgency,
  carNumber,
  type,
  createdAt,
  updatedAt,
  user,
  role,
  status,
  comments,
  isLoading,
}) => {

  const [newStatus, setNewStatus]=useState('');
  const [newUrgency, setNewUrgency]=useState('');
  const [comment, setComment]=useState('');
  const [closed, isClosed]=useState(false);
  const currentRole = useSelector(checkRole);

  const handleStatusChange = (event) => {
    setNewStatus(event.target.value); 
  };

  const handleUrgencyChange = (event) => {
    setNewUrgency(event.target.value);
  };
  

  const onSubmit = async()=>{
    try {
      const {data} = await axios.post(`/comment?post=${id}`, {comment})
      console.log('додали комент');
      refreshPage();
    } catch (err) {
      console.error(err);
      alert('не вдалося додати комент');
    }
  }

  const updateComment = (status, urgency)=>{
    let comment = '';
    const statusComment = `Статус: ${status};`;
    const urgencyComment = `Пріоритет: ${urgency};`;
    if (status!==''&& urgency===''){
      comment = statusComment;
    }else if(status===''&& urgency!==''){
      comment = urgencyComment;
    }else{
      comment = `${statusComment} ${urgencyComment}`
    }
    return comment;
  }

  const submitUpdate = async()=>{
    try {      
      const updComment = await updateComment(newStatus, newUrgency);
      const {data} = await axios.patch(`/cabinet/posts/${id}`, {status:newStatus, urgency:newUrgency});
      const {newData} = await axios.post(`/comment?post=${id}`, {comment:updComment});

      console.log('status changed');
      refreshPage();
    } catch (err) {
      console.error(err);
      alert('не вдалося змінити статус');
    }
  }
  useEffect(()=>{

    if(status==='закрито'||currentRole==='driver'){
      isClosed(true)
    }
    // console.log(closed);
  },[status])

  return (
    <Grid container justifyContent="center" spacing={1} m={1}>
      <Grid xs={12} sm={6} md={5} lg={4} xl={4}>
            <Paper className={styles.infoCard}>
                <TitleCard
                  role={role} 
                  user={user} 
                  carNumber={carNumber?carNumber:'помилка'} 
                  text={text} 
                  createdAt={createdAt} 
                  type={type?type:'помилка'}
                  isLoading={isLoading}
                />
                <br/>
                {isLoading?null:(
                  <Box>
                  <Statuses newStatus={newStatus} handleStatusChange={handleStatusChange} value={status} disabled={closed}/>
                  <Urgency newUrgency={newUrgency} handleUrgencyChange={handleUrgencyChange} value={urgency} disabled={closed}/>
                  {(!closed)?
                    <Button variant='contained' onClick={submitUpdate} disabled={newStatus!==''||newUrgency!==''?false:true}>
                      Змінити
                    </Button>:null}
                </Box>
                )}
              </Paper>
              <PhotoGalery items={problem} isLoading={isLoading} />
      </Grid>
      <Grid xs={12} sm={6} md={7} lg={8} xl={6}>
        <CommentsBlock 
          items={comments} 
          isLoading={isLoading} 
          setComment={setComment} 
          onSubmit={onSubmit} 
          comment={comment}
          disabled={closed}
          role={currentRole}
        />
      </Grid>
    </Grid>
  );
};
