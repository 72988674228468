import * as React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import  TableTemplate  from '../Table';

import { FormarttedDate } from '../../utils';
import { headCells, trailerCells } from './headCells';

const applyType = (value) => {
  switch (value) {
    case 'приймаю':
      return (
          <>
            {value}
            <KeyboardDoubleArrowDownIcon sx={{ color: '#d32f2f', height: '15px'}} />
          </>
      );
    case 'здаю':
      return (
        <>
          {value}
          <KeyboardDoubleArrowUpIcon sx={{ color: 'blue', height: '15px'}} />
        </>
      );
    default:
      return null;
  }
};

const LogButton = (value, id, slug)=>{
  return(
    <Link to={`/cabinet/${slug}/${id}`}>
      <Button variant='outlined' size='small'>
        {applyType(value)}
      </Button>
    </Link>  
  )
}

const SubstrText = (text)=>{
  const newText = text==='' ?'—':(text.length > 20? text.substr(0, 20)+' ...':text)
   return newText
 }

const validateData = (value) => {
  let color;
  let font;

  if (value < 4) {
    color='#d32f2f';
    font='white';
  } else if (value >= 4 && value < 7) {
    color='#e1e106';
    font='white';
  }

  let component = <Box 
                    sx={{ 
                      borderRadius:'15px', 
                      textAlign:'center', 
                      backgroundColor:color,
                      color:font
                      }}>
                      {value}
                  </Box>

  return component
};

// const isDocs = (data) => {
//   if (data && Object.values(data).every(value => value === true|| value===null)) {
//     return true;
//   }
//   return false;
// };

function createData(item, role) {
  const {
    company,
    _id,
    car,
    type,
    createdAt, 
    user,  
    outLook,
    tyreConditions,
    // docsComplectations,
    // inventory
  } = item;
  const {carNumber, brand } = car;

  const { lookNote, lookRating } = outLook;
  const { 
    bTyreRating, 
    fTyreRating, 
    tNote, 
    // outerDefects 
  } =tyreConditions;

  const { fName, sName } = user;
  const username = `${sName} ${fName.substr(0,1)}.`;
  const button = LogButton(type, _id, 'carlogs');
  const date = <FormarttedDate key={_id} date={createdAt} />
  const formatedNote = SubstrText(lookNote);
  const formatedNote2 = SubstrText(tNote);

  const formatedRating1 =  validateData(lookRating);
  const formatedRating2 =  validateData(fTyreRating)
  const formatedRating3 =  validateData(bTyreRating)


  return { 
    button,
    company,
    carNumber,
    // brand,
    username,
    formatedNote,
    formatedRating1,
    formatedRating2,
    formatedRating3,
    formatedNote2,
    date,
  };
}

export const NewCarlogsTemplate = ({logs, role})=>{

  const rows = logs.items.map(item=>createData(item));

  return (
    <Box mt={5} sx={{ width: '100%'}}>
      <Paper sx={{ width: '100%', mb: 2, mt:1 }}>
        <TableTemplate data={rows} headCells={headCells} role={role}/>
      </Paper>
    </Box>
  );
}


function createTrailerData(item) {
  const {
    company,
    _id,
    trailer,
    type,
    createdAt, 
    user,  
    outLook,
    tyreConditions,
  } = item;
  const trailerNumber = trailer.trailerNumber;
  const trailerType = trailer.type.substr(0, 3)+'.';

  const { lookNote } = outLook;
  const { axis_1, axis_2, axis_3, tNote, zapasNumber } =tyreConditions;

  const { fName, sName } = user;
  const username = `${sName} ${fName.substr(0,1)}.`;
  const button = LogButton(type, _id, 'trailerlogs');
  const date = <FormarttedDate key={_id} date={createdAt} />
  const formatedNote = SubstrText(lookNote);
  const formatedNote2 = SubstrText(tNote);

  const formatedRating1 =  validateData(axis_1);
  const formatedRating2 =  validateData(axis_2)
  const formatedRating3 =  validateData(axis_3)


  return { 
    button,
    company,
    trailerNumber,
    trailerType,
    username,
    formatedNote,
    formatedRating1,
    formatedRating2,
    formatedRating3,
    formatedNote2,
    zapasNumber,
    date,
  };
}


export const NewTrailerLogsTemplate = ({logs, role})=>{

  const rows = logs.items.map(item=>createTrailerData(item));

  return (
    <Box mt={5} sx={{ width: '100%'}}>
      <Paper sx={{ width: '100%', mb: 2, mt:1 }}>
        <TableTemplate data={rows} headCells={trailerCells} role={role} />
      </Paper>
    </Box>
  );
}

