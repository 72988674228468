import React from 'react';
import { useParams } from 'react-router-dom';
import axios from '../axios';
import { Post } from "../components/Post";

export const FullPost = () => {

  const [data, setData] = React.useState();
  const [isLoading, setLoading] = React.useState(true);
  const { id } = useParams();  



  React.useEffect(() => {
    axios
      .get(`/cabinet/posts/${id}`)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        alert('помилка при отриманні статті');
      });
  }, []);

  if (isLoading) {
    return <Post isLoading={isLoading} isFullPost />;
  }


  return (
      <Post
        id={data._id}
        text={data.text}
        problem={data.problem}
        urgency={data.urgency}
        type={!data.car&&!data.trailer?'помилка даних':(!data.car?data.trailer.type:data.car.brand)}
        carNumber={!data.car&&!data.trailer?'помилка даних':(!data.car?data.trailer.trailerNumber:data.car.carNumber)}
        createdAt={data.createdAt}
        updatedAt={data.updatedAt}
        role={data.user.role}
        user={`${data.user.fName} ${data.user.sName}`}
        status={data.status}
        comments={data.comments}
        isFullPost
      />
  );
};
