import * as React from 'react';
import { Box, Paper, Button, Grid, Typography} from '@mui/material';
import styles from '../AddPost/AddPost.module.scss';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkCar, selectCar, selectCompany, selectTrailer, checkTrailer } from '../../redux/slices/auth';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';


const userPages = [
    {
      label: 'Додати поломку',
      link: '/cabinet/add-post',
      hasCar: true,
      color:'error',
      icon:<BuildOutlinedIcon/>,
    },
    {
      label: 'Здати авто',
      link: '/cabinet/drop-car',
      hasCar: true,
      icon: <KeyboardDoubleArrowUpIcon />
    },
    {
      label: 'Прийняти авто',
      link: '/cabinet/take-car',
      hasCar: false,
      icon: <KeyboardDoubleArrowDownIcon />
    },
  
  ];
  
const trailerPages = [
    {
      label: 'Додати поломку',
      link: '/cabinet/add-post/trailer',
      color:'error',
      hasTrailer: true,
      icon:<BuildOutlinedIcon/>,
    },
    {
      label: 'Здати причіп',
      link: '/cabinet/drop-trailer',
      hasTrailer: true,
      icon: <KeyboardDoubleArrowUpIcon />
    },
    {
      label: 'Прийняти причіп',
      link: '/cabinet/take-trailer',
      hasTrailer: false,
      icon: <KeyboardDoubleArrowDownIcon />
    },
  ];
export const HomeUser =()=>{
  const hasCar = useSelector(checkCar); 
  const hasTrailer = useSelector(checkTrailer); 
  const car = useSelector(selectCar);
  const company = useSelector(selectCompany).toString();
  const trailer = useSelector(selectTrailer);

  var withTrailer = ()=>{
    if (company === 'Marshal UA'||company === 'Автовози'){
      return true;
    }
  }

    return(
        <Grid container justifyContent="center">
        <Grid item xs={12} sm={10} md={10} lg={8} xl={8} className='main-container'>
            {/* Авто */}
            <Box className='text-container'>
            {hasCar!==true?
              <>
              <Typography classes={{root: styles.title}} variant="h2">
                У тебе поки немає авто.
              </Typography>
              <Typography classes={{root: styles.subtitle}} variant="p">
                потрібно спочатку прийняти авто.
              </Typography>
              </>
            :
              <>
                <Typography classes={{root: styles.title}} variant="h2">
                Ваше авто
                </Typography>
                <Typography classes={{root: styles.subtitle}} variant="p">
                {car.brand+' '+car.carNumber}
                </Typography>
              </>
            }           
            </Box>
            <Box display={'flex'} sx={{justifyContent: 'space-evenly', flexWrap:'wrap'}}>
                {userPages.map((page, index) => (
                (page.hasCar && hasCar) || (!page.hasCar && !hasCar) ? (page.link ? (
                      <Link key={index} to={page.link} style={{ textDecoration: 'none' }} m={1}>
                        <Button key={index} className={`button hasCar-${page.hasCar}`} variant="contained" color={page.color || 'primary'} startIcon={page.icon}>
                          {page.label}
                        </Button>
                      </Link>
                      ):( 
                      <></>                     
                )) : null
              ))}
            </Box>
            <br/>
            <br/>               
            {/* причіп */}
            {withTrailer()===true ? 
              <>
                <Box className='text-container'>
                  {hasTrailer!==true?
                    <>
                    <Typography classes={{root: styles.title}} variant="h2">
                      У тебе поки немає причепу.
                    </Typography>
                    <Typography classes={{root: styles.subtitle}} variant="p">
                      потрібно спочатку прийняти причіп.
                    </Typography>
                    </>
                  :
                    <>
                      <Typography classes={{root: styles.title}} variant="h2">
                        Причеп
                      </Typography>
                      <Typography classes={{root: styles.subtitle}} variant="p">
                      {trailer.type+' '+trailer.trailerNumber}
                      </Typography>
                    </>
                  }           
                </Box>
                <Box display={'flex'} sx={{justifyContent: 'space-evenly', flexWrap:'wrap'}}>
                    {trailerPages.map((page, index) => (
                    (page.hasTrailer && hasTrailer) || (!page.hasTrailer && !hasTrailer) ? (page.link ? (
                          <Link key={index} to={page.link} style={{ textDecoration: 'none' }} m={1}>
                            <Button key={index} className='button' variant="contained" color={ page.color || 'primary'} startIcon={page.icon}>
                              {page.label}
                            </Button>
                          </Link>
                          ):( 
                          <></>                     
                    )) : null
                  ))}
                </Box>
              </>
            :null}              
          </Grid>
          </Grid>

    )
}