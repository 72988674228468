import * as React from 'react';
import Slider from '@mui/material/Slider';
import { Box, Typography} from '@mui/material';
import { ratingList } from "./CarLogFields";




export default function RatingLine(props) {

  const rating = ratingList[props.number];
  const [ratingValue, setRatingValue] = React.useState('');

  const handleRatingChange = (value) => {
    setRatingValue(value);
    props.onChangeRatingValue(value, props.inputName)
  };

  return (
    <Box sx={{ width: '100%', maxWidth:'450px', textAlign:'center', margin:'0 auto 0 auto'}}>
      <Typography 
        sx={{marginBottom:'22px'}}
        variant="p">
        {rating.uName}
      </Typography>
      <Slider
        id={rating.name}
        min={0}
        max={10}
        defaultValue={1}
        onChange={(event, value) => handleRatingChange(value)}
        step={1}
        marks
        valueLabelDisplay="auto"
      />
    </Box>
  );
}