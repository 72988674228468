import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';

export const fetchTrailerlogs = createAsyncThunk('trailerlogs/fetchTrailerlogs', async () => {
    const { data } = await axios.get('/cabinet/trailerlogs?timestamp=${Date.now()}');
    return data;
  }); 

export const fetchUserTrailerlogs = createAsyncThunk('trailerlogs/fetchUserTrailerlogs', async () => {
    const { data } = await axios.get('/cabinet/trailerlogs/user/:id');
    return data;
  }); 
  
  const initialState = {
    trailerlogs: {
      items: [],
      status: 'loading',
    },
  };
  
  const trailerlogSlice = createSlice({
    name: 'trailerlogs',
    initialState,
    reducers: {},
    extraReducers: {
      // отримання trailerlogs
      [fetchTrailerlogs.pending]: (state) => {
        state.trailerlogs.items = [];
        state.trailerlogs.status = 'loading';
      },
      [fetchTrailerlogs.fulfilled]: (state, action) => {
        state.trailerlogs.items = action.payload;
        state.trailerlogs.status = 'loaded';
      },
      [fetchTrailerlogs.rejected]: (state) => {
        state.trailerlogs.items = [];
        state.trailerlogs.status = 'error';
      },
      // отримання trailerlogs юзера
      [fetchUserTrailerlogs.pending]: (state) => {
        state.trailerlogs.items = [];
        state.trailerlogs.status = 'loading';
      },
      [fetchUserTrailerlogs.fulfilled]: (state, action) => {
        state.trailerlogs.items = action.payload;
        state.trailerlogs.status = 'loaded';
      },
      [fetchUserTrailerlogs.rejected]: (state) => {
        state.trailerlogs.items = [];
        state.trailerlogs.status = 'error';
      },
    },
  });
  

  export const trailerlogReducer = trailerlogSlice.reducer;