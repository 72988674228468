import React from 'react';
import { Box, Button, Paper, Typography, Grid} from '@mui/material';
import 'easymde/dist/easymde.min.css';
import styles from './ImagePreview.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ReplayIcon from '@mui/icons-material/Replay';
import axios from '../axios';
import { useFormContext } from '../components/Carlog/carlogComponents/FormContext';
import { PhotoSkeleton } from './Skeleton';


const Item = styled(Paper)(() => ({
  textAlign: 'center',
  height: '200px',
  lineHeight: '60px',
  borderRadius: '15px',
  border:'1px solid #1D3869',
  marginBottom:'15px',
  padding:'20px',
  display:'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

 //single
export const ImagePreview = (props) => {
  const { imageStates, setImage } = useFormContext();
  const inputFileRef = React.useRef(null);
  const [isLoading, setLoading] = React.useState(false);

  const imageUrl = imageStates[props.inputName] || '';

  const handleChangeFile = async (event) => {
    try {
      setLoading(true);
      const formData = new FormData();
      const file = event.target.files[0];
      formData.append(props.inputName, file);
      const { data } = await axios.post(`/upload/${props.inputName}`, formData);
      setLoading(false);
      setImage(props.inputName, data.url);
      props.onChangeImageUrl(data.url, props.inputName);
      event.target.value = '';
    } catch (err) {
      console.error(err);
      alert('помилка завантаження' + err.message);
      setLoading(false);
    }
  };

  const onClickReplaceImage = async (event) => {
    try {
      if (imageUrl) {
        const imgUrl = `./${imageUrl}`;
        await axios.delete('/upload', { data: { imgUrl } });
        setImage(props.inputName, '');
        event.target.value = '';
        props.onChangeImageUrl('');
      }
      inputFileRef.current.click();
    } catch (err) {
      console.error(err);
      alert('помилка видалення файлу');
    }
  };

  React.useEffect(() => {}, [imageUrl]); 

  return (
    <Box aria-label="upload-single-photo">
      <Typography
        className={styles.subtitle}
        variant='p'
      >
        {props.labelText}
      </Typography>
      <input id={props.inputName + "-photo"} ref={inputFileRef} type="file" accept="image/*" onChange={handleChangeFile} hidden />
      {imageUrl && (
        <Item 
          aria-label="photo-preview"
          sx={{
            backgroundImage: `url(${process.env.REACT_APP_API_URL}${imageUrl})`,
            backgroundSize: '100%', 
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      )}  
      {!isLoading ? 
        <div className={styles.buttons}>
        {!imageUrl && (
          <Button onClick={() => inputFileRef.current.click()} variant="contained" size="large" startIcon={<FileUploadIcon />}>
            Завантажити
          </Button>
        )
        }
        {imageUrl && (
          <Button variant="outlined" size="large"  onClick={onClickReplaceImage} startIcon={<ReplayIcon />} >
            Замінити
          </Button>
        )}
      </div>
      : <PhotoSkeleton />}
    </Box>
  );
};


export const ImagesManyPreview = (props) => {
  const { imageStates, setImage } = useFormContext();
  const inputFileRef = React.useRef(null);
  const [isLoading, setLoading] = React.useState(false);

  const imageUrls = imageStates[props.inputName] || [];

  const handleChangeFile = async (event) => {
    try {
      setLoading(true);
      const formData = new FormData();
      const files = event.target.files;

      for (let i = 0; i < files.length; i++) {
        formData.append(props.inputName, files[i]);
      }

      const { data } = await axios.post(`/uploads/${props.inputName}`, formData);
      setImage(props.inputName, data.urls);
      setLoading(false);
      event.target.value = '';
      props.onChangeImagesUrls(data.urls, props.inputName);
    } catch (err) {
      console.error(err);
      alert('помилка завантаження фото ' + err.message);
      setLoading(false);
    }
  };

  const onClickRemoveImage = async (index) => {
    try {
      const fileName = imageUrls[index];
      const imgUrl = `./${fileName}`; 
  
      // Видалення файлу з масиву imageUrls
      const updatedImageUrls = imageUrls.filter((url, i) => i !== index);
      setImage(props.inputName, updatedImageUrls);
      //оновлюємо в parent
      props.onChangeImagesUrls(updatedImageUrls, props.inputName);
  
      // Видалення файлу на сервері
      const { data } = await axios.delete('/upload', { data: { imgUrl } });
    } catch (err) {
      console.error(err);
      alert('Помилка видалення файлу');
    }
  };

  React.useEffect(() => {}, [imageUrls]);

  return (
    <Grid container justifyContent="center" sx={{ mb: '38px' }}>
      <Grid item xs={12} sm={12} md={12} lg={10} xl={6}>
        <input id={props.inputName + '-photo'} ref={inputFileRef} type="file" accept="image/*" onChange={handleChangeFile} hidden multiple />

        <Box className={styles.bigItem}>
           {!isLoading ? 
                    (imageUrls.length === 0 ? 
                      <>
                      <img alt='no-image' src='/no-image.svg' onClick={() => inputFileRef.current.click()}/>
                      <Typography variant="p" className={styles.description}>{props.number}</Typography>
                      </>
                    :null )            
                    : <PhotoSkeleton />
           }
           {imageUrls.map((url, index) => (
            <Box key={index} aria-label="multiple-photo-preview" sx={{ width: '200px' }}>
              <Item
                key={index}
                sx={{
                  backgroundImage: `url(${process.env.REACT_APP_API_URL}${url})`,
                  backgroundSize: '100%',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              />

              <Button
                variant="contained"
                size="large"
                color="error"
                aria-label={`delete-photo-${index}`}
                onClick={() => onClickRemoveImage(index)}
              >
                <CloseIcon />
              </Button>
            </Box>
          ))}
        </Box>
        {imageUrls.length === 0 ? (
          <Button
            className="button"
            onClick={() => inputFileRef.current.click()}
            variant="contained"
            size="large"
            startIcon={<FileUploadIcon />}
          >
            Завантажити
          </Button>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};
