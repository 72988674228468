import * as React from 'react';
import { useNavigate, Navigate} from 'react-router-dom';
import { NavLink as NavLinkBase } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ChecklistRtlOutlinedIcon from '@mui/icons-material/ChecklistRtlOutlined';
import UnfoldMoreDoubleOutlinedIcon from '@mui/icons-material/UnfoldMoreDoubleOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import LogoutIcon from '@mui/icons-material/Logout';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';

import styles from './Sidebar.module.scss'


import { useSelector, useDispatch } from 'react-redux';
import { selectUserName, checkRole, logout, selectIsAuth, selectCompany, checkCar} from '../../redux/slices/auth';
import { Typography } from '@mui/material';


const NavLink = styled((props) => (
  <NavLinkBase {...props} end={props.to === "/"}>
    <StyledListItem open={props.open} label={props.label} icon={props.icon} />
  </NavLinkBase>
))(({ theme }) => ({
  textDecoration: "none",
  "&.active": {
    color: "#d32f2f !important",
  }
}));

const StyledListItem = (props)=>{
  return(
    <ListItemButton
    onClick={props.onClick}
    sx={{
      minHeight: 48,
      justifyContent: props.open ? 'initial' : 'center',
      px: 2.5,
    }}
  >
    <ListItemIcon
      sx={{
        minWidth: '48px',
        mr: props.open ? 3 : 'auto',
        justifyContent: 'center',
      }}
    >
      {props.icon}
    </ListItemIcon>
    <ListItemText primary={props.label} sx={{ opacity: props.open ? 1 : 0 }} />
  </ListItemButton>
  )
}


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export const SideBar =(props)=>{
  const [navigation, setNavigation] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const company = useSelector(selectCompany);
  const userName = useSelector(selectUserName);;
  const isAuth = useSelector(selectIsAuth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = useSelector(checkRole);

   

  const managerPages = [
    {
      label: company,
      link: '/home',
      icon: <img src="/logo.svg" className={styles.icon}  alt='logo'/>,
    },
    {
      label: 'Здача причепів',
      link: '/trailerlogs',
      icon: <><UnfoldMoreDoubleOutlinedIcon /><RvHookupIcon /></>,
      trailers: true,
    },
    {
      label: 'Здача авто',
      link: '/carlogs',
      icon: <><UnfoldMoreDoubleOutlinedIcon /><LocalShippingIcon /></>,
    },
    {
      label: 'Поломки',
      link: '/posts',
      icon: <ChecklistRtlOutlinedIcon />,
    },
    {
      label: 'Водії',
      link: '/cabinet/users',
      icon: <PeopleOutlineIcon />,
    },
    {
      label: 'Авто',
      link: '/cabinet/cars',
      icon: <LocalShippingIcon />,
    },
    {
      label: 'Причепи',
      link: '/cabinet/trailers',
      icon: <RvHookupIcon />,
      trailers: true,
    },
    {
      label: 'Статистика',
      link: '/dashboard',
      icon: <TroubleshootIcon />,
    },
  ];

  const userPages = [
    {
      label: company,
      link: '/home',
      icon: <img src="/logo.svg" className={styles.icon}  alt='logo'/>,
    },
    {
      label: 'Поломки',
      link: '/posts',
      icon: <ChecklistRtlOutlinedIcon />
    },
    {
      label: 'Здачі авто',
      link: '/carlogs',
      icon: <UnfoldMoreDoubleOutlinedIcon />
    },
    {
      label: 'Здачі причепів',
      link: '/trailerlogs',
      trailers: true,
      icon: <UnfoldMoreDoubleOutlinedIcon />
    },
  ];
  var hasTrailers = ()=>{
    if(company==='Marshal UA'||company==='Автовози'){
      return true;
    }
  }


  React.useEffect(()=>{
    if(role==='driver'){
      return !hasTrailers()?setNavigation(userPages.filter((item)=>!item.trailers)):setNavigation(userPages)
    }else if(role==='manager'||role==='admin'){
      return !hasTrailers()?setNavigation(managerPages.filter((item)=>!item.trailers)):setNavigation(managerPages)
    }
    
  },[company])

  if (typeof window !== "undefined" && !window.localStorage.getItem('token') && !isAuth) {
    console.log('no access')
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onClickLogout = ()  => {
    if (window.confirm('Впевнені що хочете вийти?')) {
      dispatch(logout());      
      window.localStorage.removeItem('token',);
      navigate("/");
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
    {isAuth?
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              margin:'0 auto',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='p' 
            sx={{
              ...(open===false && { display: 'none' }),
            }}>
            {userName}
          </Typography>
          <IconButton onClick={handleDrawerClose} 
              sx={{
              ...(open===false && { display: 'none' }),
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {navigation.map((page) => (
            <ListItem key={page.label} disablePadding sx={{ display: 'block' }}>
              <NavLink 
                to={page.link} 
                icon={page.icon}
                key={page.label}
                open={open}
                label={page.label}  
                />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <StyledListItem open={open} label='Вийти' icon={<LogoutIcon />} onClick={onClickLogout} variant='contained' color='error'/>
        </List>        
      </Drawer> 
      : <></>}
      <Box component="main" sx={{ flexGrow: 1}}>
        {props.children}
      </Box>
    </Box>
  );
}

