export const headCells = [
    {
      id: 'urgency',
      numeric: false,
      label: 'Терміновість',
    },
    {
      id: 'text',
      numeric: false,
      label: 'Поломка'
    },
    {
      id: 'createdAt',
      numeric: true,
      label: 'Дата ств.',
    },
    {
      id: 'type',
      numeric: false,
      label: 'Марка', 
    },
    {
      id: 'carNumber',
      numeric: false,
      label: 'Номер', 
    },
    {
      id: 'user',
      numeric: false,
      label: 'Автор',
    },
    {
      id: 'problem',
      numeric: false,
      label: 'Фото',
    },
    {
      id: 'status',
      numeric: false,
      label: 'Cтатус',
    },
    {
      id: 'comment',
      numeric: false,
      label: 'Комент',
    },
    {
      id: 'updateAt',
      numeric: false,
      disablePadding: true,
      label: 'Дата оновл.'
    },
    {
      id: 'company',
      numeric: false,
      disablePadding: true,
      label: 'Компанія'
    },
    
  ];
  