import { Checklist } from "../carlogComponents/Checklist";
import { toolsList, toolsLafeta, toolsAvtovoz} from "../carlogComponents/CarLogFields";
import { Typography, Box, Radio, RadioGroup, FormControlLabel, FormControl,} from "@mui/material";
import styles from './Steps.module.scss';
import { useEffect, useState } from "react";



export const Step6 = ({step, handleChangeCheckbox, company, type, setBeltNumber})=>{
  const [list, setList ] = useState([]);
  
  const selectList = (company, type)=>{ 
    
    if(type==='Лафета'){
      // console.log('лафета')
      return setList(toolsLafeta);
    } else if(company.find((i)=>i==='Автовози')){
      // console.log('автовоз')
      return setList(toolsAvtovoz);
    }else{
      // console.log('авто')
      return setList(toolsList);
    }

  }

  useEffect(()=>{
    selectList(company, type);
  },[])

  return (
    <>
        <Box mb={3}>
          <Typography                 
            variant="h5"
            classes={{root: styles.title}}
          >
            {`0${step}. Інвентар`}
          </Typography>
          <Typography variant="p"
          classes={{root: styles.subtitle}}
          >
            Перевірте наявність інструментів
          </Typography> 
        </Box>
      <Checklist 
        list={list}
        onChange={handleChangeCheckbox}
      />
      {company[0]==='Автовози'? 
      <>
      <Box className='block' mt={6}>
          <Typography variant="subp">
            Вкажіть кількість ременів у справному стані.
          </Typography> 
        </Box>
        <br />
        <FormControl>
        <RadioGroup
          row
          aria-labelledby="radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={ (e)=> setBeltNumber(e.target.value)}
        >
          <FormControlLabel value="0" control={<Radio />} label="0" />
          <FormControlLabel value="1" control={<Radio />} label="1" />
          <FormControlLabel value="2" control={<Radio />} label="2" />
          <FormControlLabel value="3" control={<Radio />} label="3" />
          <FormControlLabel value="4" control={<Radio />} label="4" />
        </RadioGroup>
      </FormControl> 
      </>
      : null}
    </>
  );
}
