import React from "react";
import { CarlogFields } from "../carlogComponents/CarLogFields";
import { ImagesManyPreview } from "../../../utils/ImagePreview";
import { TextField, Typography } from "@mui/material";
import styles from './Steps.module.scss';


export const TrailerStep2 = ({ step, handleChangeImagesUrls, lookNote, setLookNote })=>{  
  return (
    <>       
      <Typography                 
        variant="h5"
        classes={{root: styles.title}}
        >
        {`0${step}. Стан кузова`}
      </Typography>
      <Typography variant="p" classes={{root: styles.subtitle}}>
      Зробіть 1 фото кузова
      </Typography>  
      <ImagesManyPreview 
              inputName={CarlogFields[4].name}
              labelText={CarlogFields[4].uName}
              onChangeImagesUrls={(urls) => handleChangeImagesUrls(urls, CarlogFields[4].name)} 
      />
      <TextField
        classes={{ root: styles.p }}
        variant="outlined"
        placeholder="Зауваження якщо є"
        value={lookNote}
        onChange={ (e)=> setLookNote(e.target.value)}
        fullWidth
      />

    </>
  )
}

     
