import React from "react";
import RatingLine from "../carlogComponents/RatingLine";
import { CarlogFields } from "../carlogComponents/CarLogFields";
import { ImagesManyPreview } from "../../../utils/ImagePreview";
import { TextField, Typography, Box } from "@mui/material";
import styles from './Steps.module.scss';


export const Step4 = ({step, handleChangeImagesUrls, frontTyreRating, setFrontTyreRating, backTyreRating,  setBackTyreRating, tNote, setTNote})=>{

  return (
    <>
        <Box mb={3}>
          <Typography                 
          variant="h5"
          classes={{root: styles.title}}
          >
          {`0${step}. Cтан резини`}
          </Typography>
          <Typography variant="p"
          classes={{root: styles.subtitle}}
          >
          Оцініть стан резини в момент прийому
        прийому
          </Typography> 
        </Box>
      <RatingLine 
        number={1}
        value={frontTyreRating}
        onChangeRatingValue={setFrontTyreRating}
      />
      <RatingLine 
        number={2}
        value={backTyreRating}
        onChangeRatingValue={setBackTyreRating}
      />
      <Box className='block'>
      <Typography variant="subp"> Дефекти резини, якщо є</Typography>        
      <ImagesManyPreview 
              number='можна вибрати до 4-х фото з галереї'
              inputName={CarlogFields[8].name}
              labelText={CarlogFields[8].uName}
              onChangeImagesUrls={(urls) => handleChangeImagesUrls(urls, CarlogFields[8].name)} 
      />
      </Box>
      <Box className='block'>
      <Typography variant="subp"> Видимі пошкодження автомобіля </Typography>  
      <ImagesManyPreview 
              number='можна вибрати до 6-ти фото з галереї'
              inputName={CarlogFields[9].name}
              labelText={CarlogFields[9].uName}
              onChangeImagesUrls={(urls) => handleChangeImagesUrls(urls, CarlogFields[9].name)} 
      />
      </Box>
      <TextField
        classes={{ root: styles.p }}
        variant="outlined"
        placeholder="Зауваження до стану"
        value={tNote}
        onChange={ (e)=> setTNote(e.target.value)}
        fullWidth
      />
    </>
  );
}

