import React from "react";
import { Checklist } from "../carlogComponents/Checklist";
import { docsList, docsMarshalUA, docsTrailer} from "../carlogComponents/CarLogFields";
import { Typography, Box } from "@mui/material";
import styles from './Steps.module.scss';


export const Step5 = ({step, handleChangeCheckbox, company, veicle, type})=>{
  const [list, setList]= React.useState([]);

  const selectList = (company, veicle)=>{  
    if(veicle){
      if (type!=='Рефрижиратор'){
        let cuttedList = docsTrailer.slice(0,4);      
        return setList(cuttedList);
      }
      return setList(docsTrailer);
    }

    if (company.find((i)=>i==='Marshal Trans')) {
      setList(docsList);
    } else if(company.find((i)=>i==='Marshal UA')){
      setList(docsMarshalUA);
    } else {
      setList(docsMarshalUA);
    }
  }

  React.useEffect(()=>{
    selectList(company, veicle);
  },[])
;
  return (
    <>
        <Box className='block'>
          <Typography                 
          variant="h5"
          classes={{root: styles.title}}
          >
          {`0${step}. Документи`}
          </Typography>
          <Typography variant="p"
          classes={{root: styles.subtitle}}
          >
          Перевірте наявні документи
          </Typography> 
        </Box>
      <Checklist 
        list={list}
        onChange={handleChangeCheckbox}
      />
    </>
  );
}
