import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserTrailerlogs, fetchTrailerlogs } from '../../redux/slices/trailerlog';
import { checkRole } from '../../redux/slices/auth';
import { NewTrailerLogsTemplate } from "../../components/LogsTemplate";
import Skeleton from '@mui/material/Skeleton';


export const TrailerLogs = () => {
  const [isLoading, setLoading] = React.useState(true);
  const dispatch = useDispatch();
  const role = useSelector(checkRole);

  const {trailerlogs} = useSelector(state=>state.trailerlogs);


  React.useEffect(()=>{
    const fetchData = async () => {
        if (role !== undefined && role !== null) {
            if (role !== 'driver') { 
              await dispatch(fetchTrailerlogs());
              return setLoading(false);
            } else {
              await dispatch(fetchUserTrailerlogs());
              return setLoading(false);
            }
        }else{
          console.log('has problem')
        }
    };
    fetchData();
  },[role])

  if (isLoading) {
    return <Skeleton variant="rounded" width={'100%'} height={300} sx={{mt: 5}} />
  }

  return (
    <NewTrailerLogsTemplate logs={trailerlogs} role={role}/>
  );
};