import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
// import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
// import { visuallyHidden } from '@mui/utils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCars } from '../../redux/slices/cars';
import { checkRole } from '../../redux/slices/auth';
// import  IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
// import RateReviewIcon from '@mui/icons-material/RateReview';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
// import CloseIcon from '@mui/icons-material/Close';
import axios from '../../axios';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import BasicTabs from '../../components/Tabs/BasicTabs';
import { Typography } from '@mui/material';
import styles from './Cars.module.scss';



export const Cars = ()=>{
  const dispatch = useDispatch();
  const {data: cars}= useSelector(state=>state.cars);
  const role = useSelector(checkRole);

  React.useEffect(()=>{    
      dispatch(fetchCars());
  },[role, dispatch])


  return (
    <>
    <Box className='main-container'>
    <Box sx={{display:'flex', justifyContent:'space-between'}}>
      <Typography variant='h2' classes={{root:styles.title}} >Список Авто</Typography> 
      <Link  to='/cabinet/add-car' style={{ textDecoration: 'none' }} m={1}>
        <Button  variant="contained" color='primary' >
          Додати
        </Button>
      </Link>
    </Box>
    </Box>
    <BasicTabs 
          title1='Активні'
          tab1={<CarsTable data={cars} isActive={true}  role={role} />} 
          title2='Неактивні' 
          tab2={<CarsTable data={cars} isActive={false} role={role} />} 
        />
    </>
  );
}

export const CarsTable = ({ data, isActive, role })=>{
  const navigate = useNavigate();

  const dropCar = async(id, carNumber)=>{
    if (window.confirm(`Ви, впевнені що звільнити авто ${carNumber}?`)) {
      try {
        await axios.put(`/manage/drop-car?carId=${id}`);
        console.log('car suspended');
        alert('авто вільне')
      } catch (err) {
        console.error(err);
        alert('не вдалося змінити статус');
      }
    }
  }

  const suspend = async(id, active, user, carNumber)=>{
    if (user){
      return alert(`Авто спочатку потрібно здати`)
    }

    if (window.confirm(`Ви, впевнені що приховати авто ${carNumber}?`)) {
      let status = true;
      if(active===true){
        status=false;
      }
      try {
        await axios.put(`/manage/suspend-car/${id}?active=${status}`);
        alert('авто приховали')
        window.location.reload(false);
      } catch (err) {
        console.error(err);
        alert('не вдалося змінити статус');
      }
    }
  }

  const FilteredNumbers = ({posts})=>{
    if(!posts||posts.length<=0){
      return null
    }
    const statuses = ['новий', 'в роботі', 'зроблено', 'закрито'];
    let numbers = [];    
    for (let i = 0; i < statuses.length-1; i++) {
      const filteredPosts = posts.filter((item) => (item.status === statuses[i]&& item.status !=='закрито'));
      const number = filteredPosts.length;
      const obj = {};
      obj[statuses[i]] = number;
      numbers.push(obj)
    }
    const data = numbers.map((item)=> JSON.stringify(item).replace(/{|}|"|"/g, '').replace(/:/g, ': ').trim())

    return  <Typography variant='note'>
              {data.map((item) => (
                  `${item}/ `
              ))}
            </Typography>
  }

  const Rows = ({data}) => {
    return (
      data ? 
        data.map((item, index) => (
          item.active===isActive? 
          <TableRow
            hover
            // tabIndex={-1}
            key={index}
            sx={{ cursor: 'pointer'}}
            align="left"
            className={`table-row table-row--${item.active?'active':'not-active'}`}
          >
            <TableCell className='inner'>{item.brand}</TableCell>
            <TableCell className='inner'>
              <Link to={`/cars/${item._id}`}>
                {item.carNumber}
              </Link>
            </TableCell>
            <TableCell className='inner'>{item.user===null?'вільна':
              <>
              {`${item.user.fName} ${item.user.sName}`}
                  <IconButton 
                    onClick={()=>dropCar(item._id, item.carNumber)} 
                    size='small' 
                    color='primary' 
                    aria-label='set-free' 
                    sx={{marginLeft:'10px'}}
                    disabled={!item.active||role==='mechanic'}
                  >
                    <ReplayIcon/>
                  </IconButton>
              </>
              }
            </TableCell>
            <TableCell className='inner'>{item.company}</TableCell>
            <TableCell align='left'>
                <FilteredNumbers posts={item.posts} />
                <Button 
                  color='error'
                  variant='outlined'
                  size='small'
                  startIcon={<BuildOutlinedIcon/>}
                  onClick={()=>navigate(`/cabinet/add-post/car/${item._id}`)} 
                  disabled={!item.active}
                >
                  поломка
                </Button>
            </TableCell>
            <TableCell align='left'>
              <Button 
                onClick={()=>suspend(item._id, item.active, item.user, item.carNumber)}  
                startIcon={item.active?<DoNotDisturbAltIcon color='error'/>:<RefreshIcon color='primary' />} 
                aria-label='suspend'
                disabled={!item.active||role==='mechanic'}
              >
                {item.active?'приховати':'активувати'}
              </Button>
            </TableCell>
          </TableRow>
          :null
      )):[]
    );
  }
     
  return (
      <Paper sx={{ width: '100%', mb: 2, mt:1 }}>
        <TableContainer>
          <Table
            // sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          > 
            <TableBody>
              <Rows data={data}/>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
  );
}