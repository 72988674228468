import React, { useEffect, useState } from 'react';
import axios from '../../axios';
import styles from "./NewCarlogPage.module.scss";
import { styled } from '@mui/material/styles';
import { Grid, Typography, Box, Container, Button }from '@mui/material';
import { FormarttedDate } from '../../utils';
import FileDownloadDoneOutlinedIcon from '@mui/icons-material/FileDownloadDoneOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { PhotoBlock } from '../PhotoBlock';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&::before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&::after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const getFontColor = (ratingValue, rates) => {
        const {minRate, midRate}= rates;
        
        if (ratingValue <= minRate) {
        return {color:'#d32f2f', fontWeight:'700', };
        } else if (ratingValue > minRate && ratingValue <= midRate) {
        return {color:'#f38b04', fontWeight:'700', };
        } else {
        return 'initial';
        }
};
  
const hasData = (data)=>{
    if (data && Object.values(data).every(value => value ==='')) {
        return true;
    }
    return false;
}

const applyType = (value) => {  
  switch (value) {
    case 'приймаю':
      return (
          <>
            <KeyboardDoubleArrowDownIcon sx={{ color: '#d32f2f', height: '15px'}} />
            {value}
          </>
      );
    case 'здаю':
      return (
        <>
          <KeyboardDoubleArrowUpIcon sx={{ color: 'blue', height: '15px'}} />
          {value}
        </>
      );
    default:
      return null;
  }
};
  
const StyledRating = ({value, label}) => {
    const ratingValue = parseInt(value);
    let minRate = 4;
    let midRate = 7;
    let maxRate = 10;

    if(label==='запаски'){
        minRate=0;
        midRate=1;
        maxRate=2;
    }else if(label==='розпорки'){
        minRate=0;
        midRate=3;
        maxRate=5;
    }else if(label==='ремені'){
      minRate=0;
      midRate=2;
      maxRate=4;
  }

    const rates = {minRate, midRate};

    if (!isNaN(ratingValue)) {
        const fontStyle = getFontColor(ratingValue, rates);
        return (
        <Typography sx={{fontStyle}}>
            {`${label}: ${ratingValue}/${maxRate}`}
        </Typography>
        );
    } else {
        return null;
    }
};

const ControlledSwitch = ({setOpen})=>{
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setOpen(event.target.checked);
  };

  return (
    <Android12Switch
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  );
}

export const Lists = ({ list, title, id, slug, role})=>{
    const [opened, setOpened] = useState(false)
    const [checkboxStates, setCheckboxStates] = useState({});
    const [comment, setComment] = useState({});   

    const isValid = Object.keys(comment).length === 0?false:true;


    useEffect(() => {
      const initialState = {};
      list.forEach(item => {
        initialState[item] = true;
      });
      setCheckboxStates(initialState);
    }, []);
    
    const handleChangeCheckbox = (e, checkboxName, label) => {
      const isChecked = e.target.checked;
      setCheckboxStates(prevStates => ({
        ...prevStates,
        [checkboxName]: isChecked
      }));

      const status = isChecked ? 'є' : 'немає';
      setComment(prevComment => {
        return {
          ...prevComment,
          [label]: status
        };
      });
    };


  
    const saveData = async()=>{
      try {
        const commentString = Object.entries(comment)
          .map(([key, value]) => `${key}: ${value}`)
          .join('; ');
        
        const {data} = await axios.patch(`/manage/${slug}/${id}`, checkboxStates);
        const {newData} = await axios.post(`/comment?${slug}=${id}`, {comment:commentString});
        
        setOpened(false);
        alert('дані змінили')
      } catch (err) {
        console.error(err);
      }
    }

    

  
    return(
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={styles.docList}>
                <Typography variant="subp">{title}</Typography>
                {role!=='driver'?<ControlledSwitch setOpen={setOpened} /> : null}
                <NewList 
                  handleChange={handleChangeCheckbox}
                  handleSubmit={saveData}
                  list={list} 
                  disabled={opened}
                  valid={isValid}
                />
        </Grid>
    )
}
  
export const Title = ({fields})=>{
    const {carBrand, carNumber, username, date, type}= fields;

    return (
            <Container classes={{root: styles.container}}> 
                <Typography variant='note'>
                  <FormarttedDate date={date}/>
                </Typography>
                <Typography classes={{root: styles.title}} variant="h1">
                    {`${username} `}            
                </Typography>
                <Typography classes={{root: styles.subtitle}} variant="p">
                  {applyType(type)}<br/>
                </Typography>
                <Typography classes={{root: styles.title}} variant="h1">
                  {`${carBrand} ${carNumber}`}          
                </Typography>
            </Container>
    )
}
  
export const Raiting = ({ratings})=>{
    /* Короткий опис */
    return( 
      <Container classes={{root: styles.container}}>
              <Typography variant='subp'>Оцінки</Typography>
              {ratings.map((item, index)=>(
                item.value !==''?
                <StyledRating value={item.value} label={item.label} key={index}/>
                :null
              ))}

      </Container>
    )
}
  
export const Outlook = ({outLook, title})=>{
    return(  
          <> 
            <Container classes={{root: styles.container}}>
              <Box classes={{root: styles.textContainer}}>          
                  <Typography variant="subp">{title}</Typography>
              </Box>
              <Grid container spacing={1}>
                {outLook.slice(0,4).map((item, index)=>(
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={index}>              
                  <PhotoBlock url={item.url} label={item.label} key={index} />
                </Grid>
                ))}
              </Grid>
            </Container>

            <Container classes={{root: styles.container}}>
              <Box classes={{root: styles.textContainer}}>          
                  <Typography variant="subp">{outLook[4].label}</Typography>
              </Box> 
              <Box sx={{ flexGrow: 1 }} mb={4}>
                <Grid container spacing={2}>             
                  {outLook[4].url.map((url, index)=>(
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={index}>                
                    <PhotoBlock url={url} key={index} />
                  </Grid>
                  ))}
                </Grid>
              </Box>
            </Container>
            </>
    )
}
  
export const TechCondition = ({panel, title})=>{
    return(
      <Container classes={{root: styles.container}}>
      <Box classes={{root: styles.textContainer}}>          
          <Typography variant="subp">{title}</Typography>
      </Box>
        <Box sx={{ flexGrow: 1}}>
          <Grid container spacing={2} sx={{height:'100%', justifyContent:'center'}}>
            {panel.map((item, index)=>(
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={index}>
              <PhotoBlock url={item.url} key={index} label={item.label}/>
            </Grid>
            ))}
          </Grid>
        </Box>
    </Container>
    )
}
  
export const ConditionalBlock = ({data, note, title, isOk})=>{
    return(
      <>
        {title?
        <Box>
          <Typography variant="subp">{title||'нема'}</Typography>
        </Box>
        : null}        

        {data?
          <React.Fragment>
            {hasData(data.url) ? (
              null
            ) : (
              <Box>
                {isOk? <FileDownloadDoneOutlinedIcon  />: <CloseIcon color='error' />}
                  <Typography variant='note'>
                    {data.label}
                  </Typography>
                <Box sx={{ flexGrow: 1 }} mb={2}>
                  <Grid container spacing={2} sx={{justifyContent:'left'}}>
                    {data.url.map((url, photoIndex) => (
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={photoIndex}>
                        <PhotoBlock url={url} key={photoIndex} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            )}
          </React.Fragment>
        : null}

        {/* коментарі */}
        {note && note.text!=="" ?(
            <>
              <CloseIcon color='error' />
              <Typography variant='note'>
                {note.text}
              </Typography>
            </>           
        ):(
          null
        )}
      </>
    )
}

export const NewList = ({list, disabled, handleChange, handleSubmit, valid})=>{
  
  function handleValue(e, name, label ){
    handleChange(e, name, label); 
  }


  return(
    <FormGroup>              
      {list.map((doc, index)=>(
            <FormControlLabel
              key={`${doc.label}-${index}`}
              label={
                <Typography 
                  variant='note'
                  color={hasData(doc.value)===false?'error':'primary'}
                  sx={{fontWeight:hasData(doc.value)===false?'600':'400'}}
                >
                {doc.label}
                </Typography>
              }
              control={
              <Checkbox
                size='small'
                disabled={!disabled}
                sx={{padding:'3px !important'}}
                defaultChecked={hasData(doc.value) === true?true:false}
                onChange={(e) => handleValue(e, doc.name, doc.label)}
                key={`${doc.label}-${index}`}
              />
              } 
            />                    
      ))}
      {disabled?       
        <Button variant='contained' 
          onClick={handleSubmit} 
          size='small' 
          sx={{width:'50%', marginRight:'0'}}
          disabled={!valid}
        >
          Збереги
        </Button> 
      :null}
    </FormGroup>
  )
}