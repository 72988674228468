import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Grid } from '@mui/material';

import 'easymde/dist/easymde.min.css';
import styles from '../../components/Carlog/Log.module.scss';
import { logout} from '../../redux/slices/auth';
import { useNavigate } from 'react-router-dom';
import { Navigate } from "react-router";
import { useSelector, useDispatch } from 'react-redux';
import { selectTrailer, selectCompany, checkTrailer, checkRole } from '../../redux/slices/auth';
import { Step1, TrailerStep2, TrailerStep3, TrailerStep4, Step5, Step6} from '../../components/Carlog/steps';
import axios from '../../axios';
import { FormProvider } from "../../components/Carlog/carlogComponents/FormContext";


 
export const DropTrailer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const company = useSelector(selectCompany);
  const trailer = useSelector(selectTrailer);
  const trailerId = trailer._id;
  const veicle = 'Trailer';

  let stepsNumber;
  if(company[0]!=='Автовози'){
    stepsNumber=5;
  }else{
    stepsNumber=6;
  }

  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [frontPhoto, setFrontPhoto] = useState('');
  const [rightPhoto, setRightPhoto] = useState('');
  const [leftPhoto, setLeftPhoto] = useState('');
  const [backPhoto, setBackPhoto] = useState('');
  const [salonPhotos, setSalonPhotos] = useState([]);
  const [lookNote, setLookNote] = useState('');


  const [axis_1, setAxis_1] = useState('');
  const [axis_2, setAxis_2] = useState('');
  const [axis_3, setAxis_3] = useState('');
  const [tyreDefects, setTyreDefects] = useState([]);
  const [outerDefects, setOuterDefects] = useState([]);
  const [tNote, setTNote] = useState('');
  const [zapasNumber, setZapasNumber] = useState('');
  const [zapasPhotos, setZapasPhotos] = useState([]);
  const [item_1, setItem_1] = useState('');
  const [itemPhotos, setItemPhotos] = useState([]);
 
  const [techPasport_1, setTechPasport_1] = useState(false);
  const [techPasport_2, setTechPasport_2] = useState(false);
  const [insurance_1, setInsurance_1] = useState(false);
  const [insurance_2, setInsurance_2] = useState(false);
  const [licence_1, setLicence_1] = useState(false);

  const [tool_1, setTool_1] = useState(false);
  const [tool_2, setTool_2] = useState(false);

  const [beltNumber, setBeltNumber] = useState('');

  const [isNextButtonDisabled, setNextButtonDisabled] = React.useState(true);
  const isStepValid = () => {
    // return true;
    if (activeStep === 0) {
      return frontPhoto && rightPhoto && leftPhoto && backPhoto;
    } else if (activeStep === 1) {
      return salonPhotos.length > 0;
    } else if (activeStep === 2) {
      return axis_1 && axis_2 ;      
    } else if (activeStep === 3) {
      return (
        ((zapasNumber==='0')? true: (zapasPhotos.length>0?true:false))
        && (trailer.type!=='Рефрижиратор'? true:
        (item_1==='0'?true:(itemPhotos.length>0?true:false)
        ))
      )
    } else if(activeStep === 4){
      return true;
    } else if(activeStep === 5){
      return trailer.type!=='Лафета'?true:beltNumber;
    }
  };


  const handleChangeImageUrl = (url, inputName) => {
    switch (inputName) {
      case 'front':
        setFrontPhoto(url);
        break;
      case 'right':
        setRightPhoto(url);
        break;
      case 'left':
        setLeftPhoto(url);
        break;
      case 'back':
        setBackPhoto(url);
        break;
      default:
        break;
    }

  };

  const handleChangeImagesUrls = (urls, inputName) => {
    switch (inputName) {
      case 'salon':
        setSalonPhotos(urls);
        break;
      case 'tyreDefects':
        setTyreDefects(urls);
        break;
      case 'outerDefects':
        setOuterDefects(urls);
        break;
      case 'zapasPhotos':
        setZapasPhotos(urls);
        break;
      case 'itemPhotos':
        setItemPhotos(urls);
        break;      
      default:
        break;
    }
  }
  const handleChangeCheckbox = (event, checkboxName) => {
    switch (checkboxName) {
      case 'techPasport_1':
        setTechPasport_1(event.target.checked);
        break;
      case 'techPasport_2':
        setTechPasport_2(event.target.checked);
        break;
      case 'insurance_1':
        setInsurance_1(event.target.checked);
        break;
      case 'insurance_2':
        setInsurance_2(event.target.checked);
        break;
      case 'licence_1':
        setLicence_1(event.target.checked);
        break;
      case 'tool_1':
        setTool_1(event.target.checked);
        break;
      case 'tool_2':
        setTool_2(event.target.checked);
        break; 
      default:
        break;
    }
  };

  useEffect(() => {
    setNextButtonDisabled(!isStepValid());
    console.log(stepsNumber);
  }, [
    activeStep,
    frontPhoto,
    rightPhoto,
    leftPhoto,
    backPhoto,
    salonPhotos,
    axis_1, 
    axis_2,
    axis_3,
    zapasNumber,
    zapasPhotos,
    item_1,
    itemPhotos,
    beltNumber,
  ]);

  const hasTrailer = useSelector(checkTrailer); 
  const role = useSelector(checkRole);

  if (!hasTrailer||company[0]==='Marshal Trans'||role!=='driver'){
    return <Navigate to="/home" />  
  }

  const steps = [
    {
      component: <Step1 
                  handleChangeImageUrl={handleChangeImageUrl}
                  step={activeStep+1}                  
                  />,
    },
    {
      component: <TrailerStep2 
                  handleChangeImagesUrls={handleChangeImagesUrls}
                  setLookNote={setLookNote}
                  step={activeStep+1}
                  />,
    },
    {
      component: <TrailerStep3 
                company={company}
                handleChangeImagesUrls={handleChangeImagesUrls}
                axis_1={axis_1}
                setAxis_1={setAxis_1}
                axis_2={axis_2}
                setAxis_2={setAxis_2}
                axis_3={axis_3}
                setAxis_3={setAxis_3}             
  
                tyreDefects={tyreDefects}
                setTyreDefects={setTyreDefects}
                outerDefects={outerDefects}
                setOuterDefects={setOuterDefects}
  
                tNote={tNote} 
                setTNote={setTNote}
                step={activeStep+1}
                />,
    },
    {
      component: <TrailerStep4 
                  handleChangeImagesUrls={handleChangeImagesUrls}
                  zapasNumber={zapasNumber}
                  setZapasNumber={setZapasNumber}
                  type={trailer.type}
                  item_1={item_1}
                  setItem_1={setItem_1}
                  step={activeStep+1}
                  />,
    },
    {
      component: <Step5
                  handleChangeCheckbox={handleChangeCheckbox}
                  company={company}
                  veicle={veicle}
                  type={trailer.type}
                  step={activeStep+1}
                  />,
    },
    {
      component: <Step6 
                  handleChangeCheckbox={handleChangeCheckbox}
                  company={company}
                  type={trailer.type} 
                  setBeltNumber={setBeltNumber}
                  step={activeStep+1}   
                  />,
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }; 
  const handleFinish = () =>{
    dispatch(logout());      
    window.localStorage.removeItem('token');
    navigate("/");
    // window.location.reload(false);
  }

  const handleClose = ()=>{
    navigate("/");
  }

  const outLook = {
    front:frontPhoto,
    right:rightPhoto,
    left:leftPhoto,
    back:backPhoto,
    salon:salonPhotos,
    lookNote:lookNote,
  };
  const tyreConditions ={
    axis_1:axis_1, 
    axis_2:axis_2,
    tyreDefects:tyreDefects,
    outerDefects:outerDefects,
    tNote:tNote,
    zapasNumber:zapasNumber,
    zapasPhotos:zapasPhotos,
  }
  const tyreAdd ={
    axis_3: axis_3,
  }
  
  const defaultDocs ={
    techPasport_1: Boolean(techPasport_1),
    techPasport_2: Boolean(techPasport_2),
    insurance_1: Boolean(insurance_1),
    insurance_2: Boolean(insurance_2),
  }
  const addDocs = {
    licence_1: Boolean(licence_1),
  }
  const inventory ={
    tool_1: Boolean(tool_1),
    tool_2: Boolean(tool_2),
    item_2: beltNumber,
  }

  const altInvetory={
    item_1:item_1,
    itemPhotos:itemPhotos,
  }


  let fields;
  if(trailer.type==='Лафета'){
    fields = Object.assign({}, outLook, tyreConditions, defaultDocs, inventory);
  }else if(trailer.type==='Рефрижиратор'){
    fields = Object.assign({}, outLook, tyreConditions, tyreAdd, defaultDocs, addDocs, altInvetory);
  }else{
    fields = Object.assign({}, outLook, tyreConditions, tyreAdd, defaultDocs);
  }

  const onSubmit = async()=>{    
    try {
      setLoading(true);
      
      const{data} = await axios.post('/trailerlog-drop', fields)      
      console.log(data)

      alert('причіп здали');
      setLoading(false); 
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } catch (err) {
      console.error(err);
      alert('не вдалося здати причіп. перевірте заповненість полів');
      setLoading(false);
    }
  }




  return (
    <FormProvider>
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={10} md={10} lg={8} xl={8} className='main-container'>  
      <div>
      {activeStep === stepsNumber ? (
        <React.Fragment>
          <Box className='text-container'>
              <Typography classes={{root: styles.title}} variant="h2">Вітаю, ви успішно здали</Typography>
              <Typography classes={{root: styles.subtitle}} variant="p">{trailer.type+' '+trailer.trailerNumber}</Typography>
            </Box>
          <Button  onClick={handleFinish} size='large' variant="contained" aria-label="повернутися">Перезайти</Button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box mb={7} >      
            <Typography classes={{root: styles.title}} variant="h2">Перевірка стану причепу</Typography>
            <Typography classes={{root: styles.subtitle}} variant="p">{trailer.type+' '+trailer.trailerNumber}</Typography>        
          </Box> 
          {steps[activeStep].component}
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-evenly', pt: 2, marginTop:'50px'}}>
            <Button
              variant="outlined" size="large"
              onClick={activeStep === 0? handleClose: handleBack}
              sx={{ mr: 1 }}
              aria-label="назад"
            >
              Назад
            </Button>
            {activeStep=== stepsNumber - 1 ?(
              <Button onClick={onSubmit} 
                    variant="contained" 
                    size="large" 
                    disabled={isNextButtonDisabled}
                    aria-label="далі"
                  >
                    {isLoading ? 'Зачекайте...' : 'Закінчити'}
                  </Button>
                ):(
                  <Button 
                    className="button" 
                    onClick={handleNext} 
                    variant="contained" 
                    size="large" 
                    disabled={isNextButtonDisabled}
                    aria-label="далі"
                  >
                    Далі
                  </Button>
            )}
          </Box>
        </React.Fragment>
      )}
    </div>
    </Grid>
    </Grid>
    </FormProvider>
  );

};

