import * as React from 'react';
import { Dashboard } from '../Dashboard';



export const HomeAdmin =()=>{
    return (
        <>
            <Dashboard company='Marshal Trans' />
            <Dashboard company='Marshal UA' />
            <Dashboard company='Автовози' />
        </>

    )
}