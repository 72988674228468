import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';

export const fetchAuth = createAsyncThunk('auth/fetchAuth', async (params) => {
    const { data } = await axios.post('/auth/login', params);
    return data;
  });

export const fetchAuthMe = createAsyncThunk('auth/fetchAuthMe', async ()=>{
    const {data}= await axios.get('/auth/me');
    return data;
})

export const fetchRegister = createAsyncThunk('auth/fetchRegister',async(params) =>{
    const {data} = await axios.post('/auth/add-new-user', params)
    return data;
})

  const initialState = {
    data: null,
    status: 'loading',
  };




const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers:{
        logout:(state)=>{
            state.data = null;
            state.role = null;
        }
    },
    extraReducers:{
        [fetchAuth.pending]: (state) => {
            state.status = 'loading';
            state.data = null;
            state.role = null;
            state.fName = null;
            state.sName = null;
            state.company = null;
            state.car = null;
            state.trailer = null;            
          },
        [fetchAuth.fulfilled]: (state, action) => {
            state.status = 'loaded';
            state.data = action.payload;
            state.role = action.payload.role;
            state.fName = action.payload.fName;
            state.sName = action.payload.sName;
            state.company = action.payload.company;
            state.car = action.payload.car;
            state.trailer = action.payload.trailer; 
            // console.log("Car object in Redux store:", state.car);
        },
        [fetchAuth.rejected]: (state) => {
        state.status = 'error';
        },
        [fetchAuthMe.pending]: (state) => {
            state.status = 'loading';
            state.data = null;
            state.role = null;
            state.fName = null;
            state.sName = null;
            state.company = null;
            state.car = null; 
            state.trailer = null;           
          },
        [fetchAuthMe.fulfilled]: (state, action) => {
        state.status = 'loaded';
        state.data = action.payload;
        state.role = action.payload.role;
        state.fName = action.payload.fName;
        state.sName = action.payload.sName;
        state.company = action.payload.company;
        state.car = action.payload.car;
        state.trailer = action.payload.trailer; 
        // console.log("Car object in Redux store:", state.car);

        },
        [fetchAuthMe.rejected]: (state) => {
        state.status = 'error';
        state.data = null;
        state.role = null;
        state.fName = null;
        state.sName = null;
        state.company = null;
        state.car = null;
        state.trailer = null;  
        },
        [fetchRegister.pending]: (state) => {
            state.status = 'loading';
            state.data = null;
        },
        [fetchRegister.fulfilled]: (state, action) => {
            state.status = 'loaded';
            state.data = action.payload;
        },
        [fetchRegister.rejected]: (state) => {
            state.status = 'error';
            state.data = null;
        },
    },
}); 

export const selectIsAuth = state=>Boolean(state.auth.data);
export const checkCar = state=>Boolean(state.auth.car);
export const checkTrailer = state=>Boolean(state.auth.trailer);
export const checkRole = state=>(state.auth.role);
export const selectUserName = state=>(`${state.auth.fName} ${state.auth.sName}`);
export const selectCar = state=>(state.auth.car);
export const selectTrailer = state=>(state.auth.trailer);
export const selectCompany = state=>(state.auth.company);


export const authReducer = authSlice.reducer;

export const { logout } = authSlice.actions;

