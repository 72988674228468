import { Typography, Box, Select, MenuItem, FormControl }from '@mui/material';
import { FormarttedDate } from '../../utils';
import ModeCommentOutlined from '@mui/icons-material/ModeCommentOutlined';
import styles from './Post.module.scss';
import React from 'react';
import Skeleton from "@mui/material/Skeleton";

export const Statuses = ({newStatus, handleStatusChange, value, disabled})=>{
  
    const statusList = ['', 'в роботі', 'зроблено', 'закрито']
    const Statuses = statusList
    ? statusList.filter((name)=>name!==value).map((name, index) => (
        <MenuItem key={index} value={name} >
          {name!==''? name:<>{value}</>}
        </MenuItem>
      ))
    : [];
  
    return(
      <FormControl sx={{ mr:1}} size='small' disabled={disabled}>
          <Select
            MenuProps={{
            disableScrollLock: true,
          }}
            displayEmpty
            id={newStatus}
            value={newStatus}                      
            onChange={handleStatusChange}
            inputProps={{ 'aria-label': 'Without label' }}
            >
            {Statuses}
          </Select>                  
      </FormControl>
    )
  }
  
export const Urgency = ({newUrgency, handleUrgencyChange, value, disabled})=>{
    
    const urgencyList = ['', 'неважливо', 'важливо', 'терміново']
    const Urgencies = urgencyList
    ? urgencyList.filter((name)=>name!==value).map((name, index) => (
        <MenuItem key={index} value={name} >
          {name!==''? name:<>{value}</>}
        </MenuItem>
      ))
    : [];
  
    return(
      <FormControl sx={{ mr:1}} size='small' disabled={disabled}>
          <Select
            displayEmpty
            id={newUrgency}
            value={newUrgency}
            onChange={handleUrgencyChange}
            inputProps={{ 'aria-label': 'Without label' }}
            MenuProps={{
            disableScrollLock: true,
            }}
            >
            {Urgencies}
          </Select>                  
      </FormControl>
    )
  }
  
export const TitleCard = ({type, carNumber, createdAt, user, text, isLoading, role})=>{
    return(
            <>
            {isLoading ? (
                <>
                <Skeleton variant="text" height={20} width='40%' />
                <Skeleton variant="text" height={20} width='60%' />
                <Skeleton variant="text" height={20} width='70%' />
                <Skeleton variant="text" height={20} width='30%' />
                </>
                 ):(
                    <React.Fragment>
                            <Box mb={2}>
                                <Typography variant='note'><FormarttedDate date={createdAt} /></Typography>
                                <br/>
                                <Typography variant='subp'>{`${type}: ${carNumber}`}</Typography> 
                                <br/>
                                <Typography variant='subp'>{`${role}: ${user}`}</Typography>  
                                <br/>
                            </Box>
                            <Box>
                                <ModeCommentOutlined mb={1} />
                                <Typography variant='p'>
                                {text}
                                </Typography>
                            </Box>
                    </React.Fragment>
            )}
            </>
    )
  }