import React, { useState } from 'react';
import {Grid, Box, Button, TextField, Typography, Select, MenuItem, OutlinedInput, FormControl, InputLabel} from '@mui/material';

import styles from './AddTrailer.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAuth, selectCompany, } from '../../redux/slices/auth';
import { Navigate, useNavigate} from 'react-router-dom';
import { fetchTrailer} from '../../redux/slices/trailers';




export const AddTrailer = () => {

  
  const navigate = useNavigate();
  /* const isTrailer = useSelector(trailerCheck); */
  const dispatch = useDispatch();
  const currentCompany = useSelector(selectCompany);
  const [company, setCompany]=useState('');
  const [type, setBrand]=useState('');
  const [trailerNumber, setTrailerNumber] = useState('');
  const [isButtonDisabled, setButtonDisabled] = React.useState(true); 

  const isValid = () => {
    return company && type && trailerNumber;
  }

  const typeList = ['Рефрижиратор', 'Контейнер', 'Тент', 'Лафета', 'Самоскид'];
  const Types = typeList
  ? typeList.map((name, index) => (
      <MenuItem key={index} value={name} >
        {name}
      </MenuItem>
    ))
  : [];

  const Companies = currentCompany.map((item, index)=><MenuItem key={index} value={item}>{item}</MenuItem>)



  const handleBrandChange = (event) => {
    setBrand(event.target.value);    
  };
  const handleCompanyChange = (event) => {
    setCompany(event.target.value);    
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    onSubmit();
  };



  const onSubmit = async (values)=>{
    try {
      const fields = {
        company,
        type,
        trailerNumber,
      }

      const data = await dispatch(fetchTrailer(fields));

      if (!data.payload) {
        return alert('не вдалося додати')
      }

      console.log('added new trailer');
      alert('причіп додали');
      await window.location.reload(false);
    } catch (err) {
      console.error(err);
      alert('не вдалося додати');
    }
  };

  React.useEffect(() => {
    setButtonDisabled(!isValid());
  }, [company, type, trailerNumber]);



  return (
    <Grid container justifyContent="center" mt={10} >
      <Grid item xs={12} sm={10} md={6} lg={4} xl={4} className='main-container'> 
      <Typography classes={{ root: styles.title }} variant="h5">
        Додати причіп
      </Typography>
          <form onSubmit={onSubmit} className='form'>
          <FormControl sx={{ mt: 1, mb:1, width: '100%', textAlign: 'center' }} >
              <InputLabel htmlFor="name-label">Компанія</InputLabel>
                  <Select
                    labelId="name-label-"
                    id={company}
                    value={company}
                    onChange={handleCompanyChange}
                    input={<OutlinedInput label="Company" />}
                    >
                    {Companies}
                  </Select>                  
            </FormControl>

          <FormControl sx={{ mt: 1, mb:1, width: '100%', textAlign: 'center' }}>
              <InputLabel htmlFor="name-label">Тип</InputLabel>
                  <Select
                    labelId="name-label-"
                    id={type}
                    value={type}
                    onChange={handleBrandChange}
                    input={<OutlinedInput label="Brand" />}
                    >
                    {Types}
                  </Select>
            </FormControl>
            <TextField
                className={styles.field}
                placeholder="номер авто"         
                value={trailerNumber}
                onChange={ (e)=> setTrailerNumber(e.target.value)}
                hiddenLabel
                fullWidth
              />
              <Button disabled={isButtonDisabled} type="button" onClick={handleButtonClick} size="large" variant="contained" fullWidth>
                Додати авто
              </Button>
          </form>
      </Grid>
    </Grid>
  );
};
