import React from 'react';
import { TextField, Box, Button, Typography, Radio, RadioGroup, FormControlLabel, FormControl, Grid } from '@mui/material';

import 'easymde/dist/easymde.min.css';
import styles from './AddPost.module.scss';
import { useSelector } from 'react-redux';
import { Navigate } from "react-router";
import { selectCar, checkRole, checkCar, selectCompany} from '../../redux/slices/auth';
import { Link, useParams } from 'react-router-dom';
import axios from '../../axios';
import { ImagesManyPreview } from "../../utils/ImagePreview";
import { FormProvider } from '../../components/Carlog/carlogComponents/FormContext';

export const AddPost = () => {
  const [isLoading, setLoading]= React.useState(true);
  const hasCar = useSelector(checkCar);
  const [data, setData] = React.useState(null);
  const [text, setText] = React.useState('');
  const [urgency, setUrgency] = React.useState('');
  const [problemPhotos, setProblemPhotos] =React.useState([]);
  const [isNextButtonDisabled, setNextButtonDisabled] = React.useState(true); 
  const [isFinished, setFinished ] = React.useState(false);
  const car = useSelector(selectCar);
  const company = useSelector(selectCompany);
  const role = useSelector(checkRole);
  const field = 'problem';
  const {id} = useParams();

  const isFormValid = () => {
    return problemPhotos.length>0 && text && urgency;
  }

  const handleChangeImagesUrls = (urls, inputName) => {  
    switch (inputName) {
      case 'problem':
        setProblemPhotos(urls);
        break;
      default:
        break;   
    }
  }

  const onSubmit = async()=>{
    try {
      const fields ={
        text,
        urgency,
        problem:problemPhotos,
      }
      if(role==='manager'||role==='admin'||role==='mechanic'){
        fields.company = data.company;
        const{post} = await axios.post(`/add-post/car/${id}`, fields)
      }else{
        fields.company = company;
        const{post} = await axios.post('/cabinet/posts', fields)
      }      
      setFinished(true);
    } catch (err) {
      console.error(err);
      alert(`не вдалося додати поломку. Сталася помилка ${err.message}`);
    }
  }

  React.useEffect(() => {
    if(role==='manager'||role==='admin'||role==='mechanic'){
      axios
      .get(`/cars/${id}`)
      .then((res) => {
        setData(res.data.car);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        alert('помилка при отримання інфо');
      });
    }else{
      setData(car);
      setLoading(false);
    }    
    setNextButtonDisabled(!isFormValid());
  }, [problemPhotos, text, urgency]);

  function refreshPage() {
    window.location.reload(false);
  }

  if (!hasCar&&role==='driver'){
    return <Navigate to="/home" />  
  }
  
  if (isLoading) {
    return <p>Loading...</p>
  }
  const Finish = ({data, slug})=>{
    return (
      <Box className='text-container'>
      <Typography classes={{root: styles.title}} variant="h2">
        Поломка успішно додана
      </Typography>
      <Typography classes={{root: styles.subtitle}} variant="p">
          {data.brand+' '+data.carNumber}
      </Typography>
      <br/>
      <br/>
        <Box className={styles.buttons} 
            sx={{
              flexWrap: 'wrap'
            }}
        >
          <Button className='button' aria-label="додати ще" onClick={refreshPage} size="large" variant="contained" >
            додати ще
          </Button>
          <Link to={slug}>
            <Button className='button' aria-label="повернутися" variant="outlined" size="large" fullWidth={true}>
              повернутися
            </Button>
          </Link>
        </Box> 
      </Box>  
    )
  }

  return (
    <FormProvider>
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={10} md={10} lg={8} xl={8} className='main-container'>
      {isFinished?<Finish data={data} slug={role==='driver'?'/home':'/cabinet/cars'}/>:
        <>
          <Box className='text-container'>
          <Typography classes={{root: styles.title}} variant="h2">
            Що сталося з автомобілем
          </Typography>
          <Typography classes={{root: styles.subtitle}} variant="p">
          {data.brand+' '+data.carNumber}
          </Typography>
          </Box>        
          <TextField
            hiddenLabel          
            variant="outlined"
            placeholder="Опишіть поломку"
            value={text}
            onChange={ (e)=> setText(e.target.value)}
            fullWidth    
            multiline
            maxRows={2}      
          />
          <br />
          <br />
          <FormControl>
          <RadioGroup
            row
            aria-labelledby="radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={ (e)=> setUrgency(e.target.value)}
            className='rdButtons'
          >
            <FormControlLabel value="неважливо" control={<Radio />} label="неважливо" />
            <FormControlLabel value="важливо" control={<Radio />} label="важливо" />
            <FormControlLabel value="терміново" control={<Radio />} label="терміново" />
          </RadioGroup>
          </FormControl>
          <br />
          <br />
          <ImagesManyPreview
                  number="можна вибрати до 4-х фото з галереї"
                  inputName={field}
                  labelText={field}
                  onChangeImagesUrls={(urls) => handleChangeImagesUrls(urls, field)} 
            />  
          <br />
          <br />   
            <Box className={styles.buttons} 
            sx={{
              flexWrap: 'wrap'
            }}>
            <Button className='button' aria-label="Зберегти і відправити" onClick={onSubmit} size="large" variant="contained" disabled={isNextButtonDisabled}>
              Зберегти і відправити
            </Button>
            <Link to="/">
              <Button className='button' aria-label="Відміна" variant="outlined" size="large" fullWidth={true}>Відміна</Button>
            </Link>
          </Box>
        </>
      }
    </Grid>
  </Grid>
  </FormProvider>
  );
};
