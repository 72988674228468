import { configureStore } from "@reduxjs/toolkit";
import { postsReducer } from "./slices/posts";
import { authReducer } from "./slices/auth";
import { carReducer } from "./slices/cars";
import { trailerReducer } from "./slices/trailers";
import { carlogReducer } from "./slices/carlog";
import { trailerlogReducer } from "./slices/trailerlog";
import { usersReducer } from "./slices/users";



const store = configureStore({
    reducer:{
        auth: authReducer,
        posts: postsReducer,
        cars: carReducer,
        trailers: trailerReducer,
        users: usersReducer,
        carlogs: carlogReducer,
        trailerlogs: trailerlogReducer,
    },
})

export default store;