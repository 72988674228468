import  IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

export const ImageIcon = ({ imgUrl }) => {
    const openImageInNewTab = () => {
      window.open(imgUrl, '_blank'); 
    };
  
    return (
      <IconButton color="primary" onClick={openImageInNewTab}>
                <Box 
                    sx={{
                        backgroundImage: 'url("/no-image.svg")',
                        width:'32px',
                        height:'32px',
                        backgroundSize: '65%', 
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }} 
                    />
            </IconButton>
    )
}