import React, { createContext, useContext, useState } from 'react';

export const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [imageStates, setImageStates] = useState({});
  const [checkboxStates, setCheckboxStates] = useState({});
  // const [ratingStates, setRatingStates] = useState({});

  const setImage = (inputName, urls) => {
    setImageStates(prevStates => ({
      ...prevStates,
      [inputName]: urls,
    }));
  };

//ratingContext doesn't work
  // const setRating = (inputName, value) => {
  //   setRatingStates((prevStates) => ({
  //     ...prevStates,
  //     [inputName]: value,
  //   }));
  // };

  const setCheckbox = (inputName, value) => {
    setCheckboxStates((prevStates) => ({
      ...prevStates,
      [inputName]: value,
    }));
  };

  return (
    <FormContext.Provider value={{  imageStates, setImage , checkboxStates, setCheckbox }}>
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = () => {
  return useContext(FormContext);
};
