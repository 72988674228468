import React from "react";
import { useFormContext } from "./FormContext";

export const Checklist = ({ list, onChange}) => {
  const { checkboxStates, setCheckbox } = useFormContext();

  function handleValue(e, field){
    onChange(e, field.name); 
    setCheckbox(field.name, e.target.checked);
  }

  return (
    <div className="container check-container">
      {list.map((field) => (
        <div className="form-check" key={field.name}>
          <input
            className="form-check-input"
            type="checkbox"
            value={field.name}
            id={field.name}
            checked={checkboxStates[field.name] || false}
            onChange={(e) => handleValue(e, field)}
          />
          <label className="form-check-label" htmlFor={field.name}>
            {field.uName}
          </label>
        </div>
      ))}
    </div>
  );
};


