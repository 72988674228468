import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from '../../axios';
import { checkRole } from '../../redux/slices/auth';
import { NewTrailerlogPage } from '../../components';




export const FullTrailerLog = () => {

  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const { id } = useParams();
  const role = useSelector(checkRole);

  React.useEffect(() => {
    axios
      .get(`/cabinet/trailerlogs/${id}`)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        alert('помилка при отриманні лога');
      });
  }, []);

  return (
    <NewTrailerlogPage data={data} isLoading={isLoading} role={role}/>
  );
};