import * as React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { FormarttedDate, ImageIcon } from '../../utils';
import { headCells } from './headCells';
import TableTemplate from '../Table';


const statuses = [
  {
    name:'новий',
    color:'#e18b93ba',    
  },
  {
    name:'в роботі',   
    color: '#ffc10796',
  }, 
  {
    name:'зроблено',
    color: '#93ddbb',
  }, 
  {
    name:'закрито',    
    color:'#b3b1ae',
  }];

const substrText =  (value, number)=>{
    return value.length>number? value.substr(0, number+1)+' ...':value    
}

const formateText = (value, id, slug)=>{
    return(
      <Link to={`/cabinet/${slug}/${id}`}>
        {substrText(value, 15)}
      </Link>  
    )
  }

export const PostsTemplate = ({data, role})=>{
  const posts = data.items;
  
  function createPostData (item){
    const { 
      _id, 
      text, 
      urgency, 
      status, 
      user, 
      createdAt, 
      car, 
      problem, 
      trailer,
      comments,
      updatedAt,
      company,
    } = item;

    const veicle = (!car&&!trailer)?{type:'error', number:'error'}:
      (car?{type:car.brand, number:car.carNumber}:{type:trailer.type, number:trailer.trailerNumber})

    const {type, number} = veicle;
    
    const formatedText = formateText(text, _id, 'posts');
    const { fName, sName, role } = user;
    const author = `${sName} ${fName.substr(0,1)}.`;
    const date1 = <FormarttedDate date={createdAt} />;
    const date2 = <FormarttedDate date={updatedAt} />;

    const photo = problem.length<=0 ? '—' : problem.map((url, index) => (
            <ImageIcon imgUrl={`${process.env.REACT_APP_API_URL}${url}`} key={index} />
        ))
    // підвантаження останнього комента
    const comment = substrText((comments.length===0)?'—':comments[comments.length - 1].comment, 20)

    return {
      urgency,
      formatedText,
      date1,
      type,
      number,
      author,
      photo,
      status,
      comment,
      date2,
      company
    }
  }

  const rows = posts.map(item=>createPostData(item));

  return (
    <Box mt={5} sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2, mt:1 }}>
        <TableTemplate data={rows} headCells={headCells} role={role} status={statuses}/>
      </Paper>
    </Box>
  );
}