import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from '../../axios';
import { selectCompany, checkRole } from '../../redux/slices/auth';
import { CarPage } from '../../components';
import Skeleton from '@mui/material/Skeleton';



export const Car = () => {

  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const { id } = useParams();
  const company = useSelector(selectCompany);
  const role = useSelector(checkRole);

  React.useEffect(() => {
    const fetchData = async () => {
    await axios
      .get(`/cars/${id}`)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        alert('помилка при отриманні лога');
      });
    }
    fetchData();
  }, []);

  if (isLoading) {
    return <Skeleton variant="rounded" width={'100%'} height={300} sx={{mt: 5}} />
  }

  return (
    <CarPage 
      data={data} 
      isLoading={isLoading} 
      company={company} 
      role={role}
    />
  );
};