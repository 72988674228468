import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserCarlogs, fetchCarlogs } from '../../redux/slices/carlog';
import { checkRole } from '../../redux/slices/auth';
import { NewCarlogsTemplate } from "../../components/LogsTemplate";
import Skeleton from '@mui/material/Skeleton';


export const Carlogs = () => {
  const [isLoading, setLoading] = React.useState(true);
  const dispatch = useDispatch();
  const role = useSelector(checkRole);

  const { carlogs } = useSelector(state=>state.carlogs);


  React.useEffect(()=>{
    const fetchData = async () => {
        if (role !== undefined && role !== null) {
            if (role !== 'driver') { 
              await dispatch(fetchCarlogs());
              return setLoading(false);
            } else {
              await dispatch(fetchUserCarlogs());
              return setLoading(false);
            }
        }else{
          console.log('has problem')
        }
    };
    fetchData();
  }, [role]);

  if (isLoading) {
    return <Skeleton variant="rounded" width={'100%'} height={300} sx={{mt: 5}} />
  }

  return (
    <NewCarlogsTemplate logs={carlogs} role={role}/>
  );
};