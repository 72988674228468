import React from "react";
import styles from './Post/Post.module.scss';
import Skeleton from "@mui/material/Skeleton";
import { FormarttedDate } from "../utils";
import { Grid, Typography, Box, TextField, Paper, Button, }from '@mui/material';

export const CommentsBlock = ({ role, items, isLoading, setComment, onSubmit, comment, disabled}) => {
  
  return (
    <Grid container spacing={1} flexGrow={1} justifyContent='space-around' className={styles.rightBlock}>
        {isLoading ? 
        (
          <Skeleton variant="rounded" height={60} width='100%' sx={{marginBottom:'20px', borderRadius:'15px'}} /> 
          ):(
            role==='driver'?null:(!disabled?<AddComment setComment={setComment} onSubmit={onSubmit} comment={comment} />:null)
        )}
        {(isLoading ? [...Array(5)] : items).map((obj, index) => (
          <React.Fragment key={index}>
              {isLoading ? (
                <div style={{ display: "flex", flexDirection: "column", marginBottom:'20px', borderRadius:'15px'}}>
                  <Skeleton variant="rounded" height={60} width='100%' />
                  <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                    <Skeleton variant="text" height={18} width={300} />
                    <Skeleton variant="text" height={18} width={200} />
                  </div>
                </div>
              ) : (
                <Comment item={obj} />
              )}
          </React.Fragment>
        ))}
    </Grid>
  );
};

const AddComment = ({setComment, onSubmit, comment})=>{
  return(
            <>
              <TextField 
                placeholder="додати коментар"
                name='comment'
                value={comment}
                onChange={ (e)=> setComment(e.target.value)}
                hiddenLabel
                fullWidth />
                <Button 
                  onClick={onSubmit}
                  size="large" 
                  variant="contained" 
                  disabled={comment===''?true:false}
                  type="submit"
                  sx={{margin:'10px 0'}}
                >
                  Додати
                </Button>
            </>
  )
}

const Comment = ({item})=>{

  return(
    <>
      <Paper 
          sx={{
            minHeight:'40px', 
            textAlign:'left', 
            padding:'15px 17px', 
            borderRadius:'15px',
            }} 
          variant="outlined" 
      >
        <Typography variant='note'>
          {item.comment}
        </Typography>
      </Paper>
      <Box sx={{
              textAlign:'right',
              display: 'flex',
              justifyContent: 'space-evenly',
              flexDirection: 'row-reverse',
              marginTop:'5px',
              marginBottom:'30px'
            }} 
      >
      <Typography variant='note'>
        {`${item.manager.fName} ${item.manager.sName}`}
      </Typography> 
      <Typography variant='note'>
        <FormarttedDate date={item.createdAt} />
      </Typography>
      </Box>
    </>
  )
}
