import { Typography, Box, Grid, Button } from "@mui/material"
import { Link }from 'react-router-dom';

export const NoMatch=({err})=>{
    return (
        <Grid container justifyContent="center" mt={20}  >
        <Grid item xs={12} sm={10} md={6} lg={4} xl={4}> 
          <Box
            sx={{
                margin: '3% auto',
                width: '100%',
                height: '100%',
                textAlign: 'center',
            }}
          >
            <Typography variant='h1'>
              {!err?'404':null}
            </Typography>
            <Typography variant='h2' mb={2}>
                {err||'такої сторінки не існує'}
            </Typography>
            <Link to='/'>
              <Button variant="contained">
                {err?'увійти':'назад'}
              </Button>
            </Link>
            </Box>
          </Grid>
          </Grid>
    )
}