import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
// import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
// import { visuallyHidden } from '@mui/utils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTrailers } from '../../redux/slices/trailers';
// import  IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
// import RateReviewIcon from '@mui/icons-material/RateReview';
import ReplayIcon from '@mui/icons-material/Replay';
import axios from '../../axios';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import BasicTabs from '../../components/Tabs/BasicTabs';
import { Typography } from '@mui/material';
import styles from './Trailers.module.scss';
import { checkRole } from '../../redux/slices/auth';


export const Trailers = ()=>{
  const dispatch = useDispatch();
  const {data: trailers}= useSelector(state=>state.trailers);
  const role = useSelector(checkRole);


  React.useEffect(()=>{   
      dispatch(fetchTrailers());
  },[role])

  return (
    <>
    <Box  className='main-container' textAlign='left'>
      <Box sx={{display:'flex', justifyContent:'space-between'}}>
        <Typography variant='h2' classes={{root:styles.title}}>Список причепів</Typography> 
        <Link  to='/cabinet/add-trailer' style={{ textDecoration: 'none' }} m={1}>
          <Button  variant="contained" color='primary' >
            Додати
          </Button>
        </Link>
      </Box>
    </Box>
    <BasicTabs 
          title1='Активні'
          tab1={<TrailerTable data={trailers} isActive={true} role={role}/>} 
          title2='Неактивні' 
          tab2={<TrailerTable data={trailers} isActive={false} role={role}/>} 
        />
    </>
  );
}

export const TrailerTable = ({ data, isActive, role })=>{
  const navigate = useNavigate();
  const dropCar = async(id, trailerNumber)=>{
    if (window.confirm(`Ви, впевнені що звільнити причіп ${trailerNumber}?`)) {
      try { 
        await axios.put(`/manage/drop-trailer?trailerId=${id}`);
        console.log('trailer suspended');
        alert('причіп вільний')
        window.location.reload(true);
      } catch (err) {
        console.error(err);
        alert('не вдалося змінити статус');
      }
    }
  }

  const suspend = async(id, active, user, trailerNumber)=>{
    if (user){
      return alert(`причіп спочатку потрібно здати`)
    }

    if (window.confirm(`Ви, впевнені що приховати причіп ${trailerNumber}?`)) {
      let status = true;
      if(active===true){
        status=false;
      }
      try {
        await axios.put(`/manage/suspend-trailer/${id}?active=${status}`);
        console.log('trailer suspended');
        alert('причіп приховали')
      } catch (err) {
        console.error(err);
        alert('не вдалося змінити статус');
      }
    }
  }

  const FilteredNumbers = ({posts})=>{
    if(!posts||posts.length<=0){
      return null
    }
    const statuses = ['новий', 'в роботі', 'зроблено', 'закрито'];
    let numbers = [];    
    for (let i = 0; i < statuses.length-1; i++) {
      const filteredPosts = posts.filter((item) => (item.status === statuses[i]&& item.status !=='закрито'));
      const number = filteredPosts.length;
      const obj = {};
      obj[statuses[i]] = number;
      numbers.push(obj)
    }
    const data = numbers.map((item)=> JSON.stringify(item).replace(/{|}|"|"/g, '').replace(/:/g, ': ').trim())

    return  <Typography variant='note'>
              {data.map((item) => (
                  `${item}/ `
              ))}
            </Typography>
  }

  const Rows = ({data}) => {
    return (
      data ? 
        data.map((item, index) => (
          item.active===isActive? 
          <TableRow
          hover
          key={index}
          sx={{ cursor: 'pointer'}}
          align="left"
          className={`table-row table-row--${item.active?'active':'not-active'}`}
        >
          <TableCell className='inner'>{item.type}</TableCell>
          <TableCell className='inner'>
              <Link to={`/trailers/${item._id}`}>
                {item.trailerNumber}
              </Link>
          </TableCell>
          <TableCell className='inner'>{item.user===null?'вільна':
            <>
            {`${item.user.fName} ${item.user.sName}`}
                <IconButton
                  disabled={!item.active||role==='mechanic'}
                  onClick={()=>dropCar(item._id, item.trailerNumber)} 
                  size='small' 
                  color='primary' 
                  aria-label='set-free' 
                  sx={{marginLeft:'10px'}}
                  >
                  <ReplayIcon/>
                </IconButton>
            </>
            }
          </TableCell>
          <TableCell className='inner'>{item.company}</TableCell>
          <TableCell className='inner'>
                <FilteredNumbers posts={item.posts} />
                <Button 
                  variant='outlined' 
                  color='error'
                  size='small'
                  startIcon={<BuildOutlinedIcon/>} 
                  onClick={()=>navigate(`/cabinet/add-post/trailer/${item._id}`)}
                >
                  Поломка
                </Button>
            </TableCell>
            <TableCell align='left'>
              <Button
                disabled={!item.active||role==='mechanic'}
                onClick={()=>suspend(item._id, item.active, item.user, item.trailerNumber)}  
                startIcon={item.active?<DoNotDisturbAltIcon color='error'/>:<RefreshIcon color='primary' />} 
                aria-label='suspend'
              >
                {item.active?'приховати':'активувати'}
              </Button>
            </TableCell>
        </TableRow>
          :null
      )):[]
    );
  }
     
  return (
      <Paper sx={{ width: '100%', mb: 2, mt:1 }}>
        <TableContainer>
          <Table
            // sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          > 
            <TableBody>
              <Rows data={data}/>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
  );
}