import React from "react";
import RatingLine from "../carlogComponents/RatingLine";
import { CarlogFields } from "../carlogComponents/CarLogFields";
import { ImagesManyPreview } from "../../../utils/ImagePreview";
import { TextField, Typography, Box } from "@mui/material";
import styles from './Steps.module.scss';


export const TrailerStep3 = ({step, handleChangeImagesUrls, axis_1, setAxis_1,  axis_2, setAxis_2, axis_3, setAxis_3, tNote, setTNote, company})=>{

  return (
    <>
      <Box className='block'>
        <Box mb={3}>
          <Typography                 
            variant="h5"
            classes={{root: styles.title}}
          >
            {`0${step}. Cтан резини`}
          </Typography>
          <Typography variant="p"
            classes={{root: styles.subtitle}}
            >
          Оцініть стан резини в момент прийому
          прийому
          </Typography> 
        </Box>
        <RatingLine 
          number={3}
          value={axis_1}
          onChangeRatingValue={setAxis_1}
        />
        <RatingLine 
          number={4}
          value={axis_2}
          onChangeRatingValue={setAxis_2}
        />
        {company!=='Автовози'?      
          <RatingLine 
            number={5}
            value={axis_3}
            onChangeRatingValue={setAxis_3}
          />
        :null}
      </Box>
      <Box className='block'>
      <Typography variant="subp"> Дефекти резини, якщо є</Typography>
      <ImagesManyPreview 
              number='можна вибрати до 4-х фото з галереї'
              inputName={CarlogFields[8].name}
              labelText={CarlogFields[8].uName}
              onChangeImagesUrls={(urls) => handleChangeImagesUrls(urls, CarlogFields[8].name)} 
      />
      </Box>
      <Box className='block'>
      <Typography variant="subp"> Видимі пошкодження автомобіля </Typography>  
      <ImagesManyPreview 
              number='можна вибрати до 6-ти фото з галереї'
              inputName={CarlogFields[9].name}
              labelText={CarlogFields[9].uName}
              onChangeImagesUrls={(urls) => handleChangeImagesUrls(urls, CarlogFields[9].name)} 
      />
      </Box>
      <TextField
        classes={{ root: styles.p }}
        variant="outlined"
        placeholder="Зауваження якщо є"
        value={tNote}
        onChange={ (e)=> setTNote(e.target.value)}
        fullWidth
      />

    </>
  );
}

