import React, { useState } from 'react';
import { Grid,  Button, TextField, Typography, Select, MenuItem, OutlinedInput, FormControl, InputLabel } from '@mui/material';

import styles from './AddCar.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectCompany, } from '../../redux/slices/auth';
import { fetchCar} from '../../redux/slices/cars';




export const AddCar = () => {

  const dispatch = useDispatch();
  const [company, setCompany]=useState('');
  const [brand, setBrand]=useState('');
  const [carNumber, setCarNumber] = useState('');
  const [isButtonDisabled, setButtonDisabled] = React.useState(true); 
  const currentCompany = useSelector(selectCompany);

  const isValid = () => {
    return company && brand && carNumber;
  }

  const brandList = ['Iveco', 'MAN', 'DAF', 'Renault', 'Scania', 'Mercedes']
  const Brands = brandList
  ? brandList.map((name, index) => (
      <MenuItem key={index} value={name} >
        {name}
      </MenuItem>
    ))
  : [];

  const Companies = currentCompany.map((item, index)=><MenuItem key={index} value={item}>{item}</MenuItem>)


  const handleBrandChange = (event) => {
    setBrand(event.target.value);    
  };
  const handleCompanyChange = (event) => {
    setCompany(event.target.value);    
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    onSubmit();
  };



  const onSubmit = async (values)=>{
    try {
      const fields = {
        company,
        brand,
        carNumber,
      }

      const data = await dispatch(fetchCar(fields));

      if (!data.payload) {
        return alert('не вдалося додати')
      }

      console.log('added new car');
      alert('авто додали');    
      await window.location.reload(false);  
    } catch (err) {
      console.error(err);
      alert('не вдалося додати авто. заповніть всі поля');
    }
  };

  React.useEffect(() => {
    setButtonDisabled(!isValid());
  }, [company, brand, carNumber]);



  return (
    <Grid container justifyContent="center" mt={10} >
      <Grid item xs={12} sm={10} md={6} lg={4} xl={4} className='main-container'> 
      <Typography classes={{ root: styles.title }} variant="h5">
        Додати авто
      </Typography>
          <form onSubmit={onSubmit} className='form'>
          <FormControl sx={{ mt: 1, mb:1, width: '100%', textAlign: 'center' }}>
              <InputLabel htmlFor="name-label">Компанія</InputLabel>
                  <Select
                    labelId="name-label-"
                    id={company}
                    value={company}
                    onChange={handleCompanyChange}
                    input={<OutlinedInput label="Company" />}
                    >
                    {Companies}
                  </Select>
            </FormControl>

          <FormControl sx={{ mt: 1, mb:1, width: '100%', textAlign: 'center' }}>
              <InputLabel htmlFor="name-label">Марка</InputLabel>
                  <Select
                    labelId="name-label-"
                    id={brand}
                    value={brand}
                    onChange={handleBrandChange}
                    input={<OutlinedInput label="Brand" />}
                    >
                    {Brands}
                  </Select>
            </FormControl>
            <TextField
                className={styles.field}
                placeholder="номер авто"         
                value={carNumber}
                onChange={ (e)=> setCarNumber(e.target.value)}
                hiddenLabel
                fullWidth
              />
              <Button disabled={isButtonDisabled} type="button" onClick={handleButtonClick} size="large" variant="contained" fullWidth>
                Додати авто
              </Button>
          </form>
      </Grid>
    </Grid>
  );
};
