import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserPosts, fetchPosts } from '../../redux/slices/posts';
import { checkRole } from '../../redux/slices/auth';
import { PostsTemplate } from "../../components/PostsTemplate/index";
import Skeleton from '@mui/material/Skeleton';


export const Posts = () => {
  const [isLoading, setLoading] = React.useState(true);
  const dispatch = useDispatch();
  const role = useSelector(checkRole);

  const { posts } = useSelector(state=>state.posts);


  React.useEffect(()=>{
    const fetchData = async () => {      
      if (role !== undefined && role !== null) {
        if (role !== 'driver') { 
          await dispatch(fetchPosts());
          return setLoading(false);
        } else {
          await dispatch(fetchUserPosts());
          return setLoading(false);
        }
      }else{
        console.log('has problem')
      }
    };  
    fetchData();
  },[role])

  if (isLoading) {
    return <Skeleton variant="rounded" width={'100%'} height={300} sx={{ mt: 5}} />
  }

  return ( 
    <PostsTemplate data={posts} role={role} />
  );
};