export const headCells = [
  {
    id: 'type',
    numeric: false,
    disablePadding: true,
    label: 'Тип'
  },
  // {
  //   id: 'carNumber',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'Автомобіль', 
  // },
  {
    id: 'username',
    numeric: false,
    disablePadding: true,
    label: 'Водій',
  },
  // {
  //   id: 'lookNote',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'Зауваження',
  // },
  // {
  //   id: 'outerDefects',
  //   numeric: false,
  //   label: 'Пошкодж.Авто',
  // },
  // {
  //   id: 'lookRating',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'Чистота',
  // },
  // {
  //   id: 'fTyreRating',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'П.Шини',
  // },
  // {
  //   id: 'bTyreRating',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'З.Шини',
  // },
  // {
  //   id: 'tyreDefects',
  //   numeric: false,
  //   label: 'Деф.Шини',
  // },
  // {
  //   id: 'tNote',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'Нотатка',
  // },
  // {
  //   id: 'docs',
  //   numeric: false,
  //   label: 'Док.',
  // },
  // {
  //   id: 'inventory',
  //   numeric: false,
  //   label: 'Інстр.',
  // },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: true,
    label: 'Дата ств.',
  },  

];