import React, { useState } from 'react';
import { Grid, Button, TextField, Typography, Select, MenuItem, OutlinedInput, FormControl, InputLabel} from '@mui/material';

import styles from './AddUser.module.scss';
import {useForm} from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { fetchRegister, selectCompany,} from '../../redux/slices/auth';




export const AddUser = () => {

  
  const dispatch = useDispatch();
  const [company, setCompany]=useState('');
  const currentCompany = useSelector(selectCompany);

  const Companies = currentCompany.map((item, index)=><MenuItem key={index} value={item}>{item}</MenuItem>)

  const handleCompanyChange = (event) => {
    setCompany(event.target.value);    
  };

  const{
    register,
    handleSubmit,
    formState:{errors, isValid}
  }=useForm({
    mode: 'onChange',
  })

  const onSubmit = async (values) => {
    values.company = company;
  
    try {
      const data = await dispatch(fetchRegister(values));
      // console.log(data);
  
      // if (!data.payload) {
      //   return alert('не вдалося додати');
      // }
  
      // if ('token' in data.payload) {
      //   window.localStorage.setItem('token', data.payload.token);
      // }
      alert('водія додали');
      await window.location.reload(false);
    } catch (err) {
      console.error(err);
      alert('не вдалося додати водія. Перевірте всі поля і спробуйте ще раз');
    }
  };



  return (
    <Grid container justifyContent="center" mt={10} >
      <Grid item xs={12} sm={10} md={6} lg={4} xl={4} className='main-container'> 
      <Typography classes={{ root: styles.title }} variant="h5">
        Додати водія
      </Typography>
        <form onSubmit={handleSubmit(onSubmit)} className='form'>
        <FormControl sx={{ mt: 1, mb:1, width: '100%', textAlign: 'center' }}>
              <InputLabel htmlFor="name-label">Компанія</InputLabel>
                  <Select
                    labelId="name-label-"
                    id={company}
                    value={company}
                    onChange={handleCompanyChange}
                    input={<OutlinedInput label="Company" />}
                    >
                    {Companies}
                  </Select>
            </FormControl>
        <TextField
              className={styles.field}
              placeholder="Ім'я"
              hiddenLabel
              error={Boolean(errors.fName?.message)}
              helperText={errors.fName?.message}
              {...register('fName', {required:"вкажіть ім'я"})}
              fullWidth
            />
          <TextField
              className={styles.field}
              placeholder="Прізвище"
              hiddenLabel
              error={Boolean(errors.sName?.message)}
              helperText={errors.sName?.message}
              {...register('sName', {required:'вкажіть прізвище'})}
              fullWidth
            />      
            {/* <TextField
              className={styles.field}
              placeholder="Телефон"
              hiddenLabel
              error={Boolean(errors.phone?.message)}
              helperText={errors.phone?.message}
              {...register('phone', {required:'вкажіть телефон'})}
              fullWidth
            /> */}
            <TextField
              className={styles.field}
              placeholder="E-Mail"
              type='email'
              hiddenLabel
              error={Boolean(errors.email?.message)}
              helperText={errors.email?.message}
              {...register('email', {required:'вкажіть пошту'})}
              fullWidth
            />        
            <TextField 
              className={styles.field} 
              placeholder="Password"
              // type='password'
              hiddenLabel
              error={Boolean(errors.password?.message)}
              helperText={errors.password?.message} 
              {...register('password', {required:'вкажіть пароль'})}
              fullWidth />
            <Button disabled={!isValid} type="submit" size="large" variant="contained" fullWidth>
              Додати користувача
            </Button>
        </form>
      </Grid>
    </Grid>
  );
};
