import * as React from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFreeCars } from '../../../redux/slices/cars';
import { ChooseCar } from '../carlogComponents/Choose-car';




export const Step0 = ({setChosenCarNumber, chosenCarId, setChosenCarId, setChosenCarBrand})=>{
    
    const dispatch = useDispatch();
    const { data: freeCarsList } = useSelector((state) => state.cars);
  
    const handleChange = (event) => {
      const selectedCarId = event.target.value;
      const selectedCar = freeCarsList.find(car => car._id === selectedCarId);
  
      if (selectedCar) {
        setChosenCarId(selectedCarId);
        setChosenCarNumber(selectedCar.carNumber);
        setChosenCarBrand(selectedCar.brand);
      }
    };

    React.useEffect(() => {
    dispatch(fetchFreeCars());
  }, []);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={10} md={10} lg={8} xl={8}>
        <ChooseCar 
          title='Авто' 
          data={freeCarsList} 
          id={chosenCarId}
          handleChange={handleChange}
        />
      </Grid>
    </Grid>
  );
};