import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { Chart } from "react-google-charts";

export function PieChart({data}) {

    const fData = [
        [data[0], 'шт'],
        ['нові', data[1].new],
        ['в роботі', data[1].inWork],
        ['зроблено', data[1].done],
        ['закрито', data[1].closed]
    ]

   const total = data[1].new + data[1].inWork + data[1].done + data[1].closed;
   const title = `${data[0]}: ${total}`

  return (
    <Paper sx={{display:'flex', flexDirection:'column', borderRadius:'15px', padding:'10px'}}>
        <Box>
        <Typography variant="subp">
            {title}
        </Typography>
        <br/>

        {data[1].avaregeCloseTime?
            <Typography variant="note">
                <FormatedTime description='Сер.час закриття:' time={data[1].avaregeCloseTime} />
            </Typography>
        :null}
        </Box>
        <Chart
            chartType="PieChart"
            data={fData}
            width={"100%"}
            height={"200px"}
        />
    </Paper>
  );
}

export const FormatedTime = ({description, time})=>{
    if (!time) {
        return;
    }  

    const {days, hours, minutes, seconds}= time;
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
    
    const day = days===0?'':`${days} днів та `;
    const result = <>{`${description}`} {`${day} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`}</>;

    return result;
}