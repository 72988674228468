import React, {useState} from "react";
import { TextField, Box, Button, Typography, Grid, InputAdornment, IconButton} from '@mui/material';
import styles from "./Login.module.scss";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Navigate } from "react-router";
import { fetchAuth, selectIsAuth } from "../../redux/slices/auth";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  
  const isAuth = useSelector(selectIsAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const{
    register,
    handleSubmit,
    formState:{errors}
  }=useForm({
    defaultValues:{
      // email:'manager@gmail.com',
      // // email:'mechanic@gmail.com',
      // email:'1@gmail.com',
      // password:'123456',
    }
  })

  const onSubmit= async (values)=>{
    const data = await dispatch(fetchAuth(values));
    // console.log(data);

    if (!data.payload) {
      return alert('не вдалося авторизуватися')
    }

    if ('token' in data.payload)  {
      window.localStorage.setItem('token', data.payload.token);
      navigate("/home")
      window.location.reload(false);
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  if (isAuth && ('token' in window.localStorage)) {
    return <Navigate to="/home" />    
  } 

  return (
    <Grid container justifyContent="center" mt={10} >
      <Grid item xs={12} sm={10} md={6} lg={4} xl={4}> 
        <Box
          sx={{
              margin: '3% auto',
              width: '100%',
              height: '100%',
              textAlign: 'center',
          }}
        >
          <img className={styles.logo} src='/logo.svg' alt='logo-marshal'></img>
          <Typography classes={{ root: styles.title }} variant="h1">
            Sign in
          </Typography>
          <Typography classes={{root: styles.subtitle}} variant="p">
            Вітаємо у ситемі Marshal Trans!
          </Typography>
          
          <form onSubmit={handleSubmit(onSubmit)} className='form'>
            <TextField
              id='email'
              className={styles.field}
              hiddenLabel
              placeholder="E-Mail"
              type='email'
              error={Boolean(errors.email?.message)}
              helperText={errors.email?.message}
              {...register('email', {required:'вкажіть пошту'})}
              suggested="username"
              fullWidth
            />
            <TextField
              id='password'
              className={styles.field}
              hiddenLabel
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              error={Boolean(errors.password?.message)}
              helperText={errors.password?.message} 
              {...register('password', {required:'вкажіть пароль'})}
              autoComplete="current-password"
              fullWidth 
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              />
            <Button type="submit" size="large" variant="contained" fullWidth>
              Login
            </Button>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};
