import React from 'react';
import styles from "./CarPage.module.scss";
import { Link } from 'react-router-dom';

import { Typography, Box, Container, Paper }from '@mui/material';

const statuses = [
  {
    name:'новий',
    color:'#e18b93ba',    
  },
  {
    name:'в роботі',   
    color: '#ffc10796',
  }, 
  {
    name:'зроблено',
    color: '#93ddbb',
  }, 
  {
    name:'закрито',    
    color:'#b3b1ae',
  }];

function applyColorStyle(statuses, value) {
    let backgroundColor = 'inherit';
    if(!statuses){
      return {backgroundColor}
    }
  
    const matchedStatus = statuses.find((statusItem) => statusItem.name === value);
  
    if (matchedStatus) {
      backgroundColor = matchedStatus.color;
    } 
    return {backgroundColor}
  }
  
export const Title = ({fields})=>{
    const {carBrand, carNumber, username, company}= fields;

    return (
            <Container classes={{root: styles.container}}>

                <Typography classes={{root: styles.title}} variant="h2">
                  {`${carBrand} ${carNumber}`}          
                </Typography>
                <Typography variant="subp">
                  {`${company}`}            
                </Typography>
                <br/>
                <br/>
                <Typography variant="subp">
                    {`${username}`}            
                </Typography>
            </Container>
    )
}
  
export const Posts = ({title, posts})=>{
    return(  
          <> 
            <Box 
              classes={{root: styles.container}} 
              spacing={1} 
              flexGrow={1} 
              justifyContent='space-around'
            >
              <Box mb={1}>          
                  <Typography variant="subp">{title}</Typography>
              </Box>
                {posts.map((item, index)=>
                  <PostItem post={item} key={index} />
                )}
            </Box>
          </>
    )
}

const PostItem = ({post})=>{
  const {
    _id,
    text,
    urgency,
    status,
    date,
    author,
    // role
  } = post;

  return(
    <Paper           
        sx={{
          backgroundColor:applyColorStyle(statuses, status),
          minHeight:'40px', 
          textAlign:'left', 
          padding:'15px 17px', 
          borderRadius:'15px',
          width:'100%',
          marginBottom:'5px',
          }} 
        variant="outlined"
    >

      <Box 
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          marginBottom:'10px',
          alignItems: 'flex-start'
        }} 
      >
      <Box
        sx={{
          textAlign:'left',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',

        }} 
      >
        <Typography variant='note'>
          {urgency}
          <br/>
          Статус: {status}
        </Typography>
      </Box>
      <Typography variant='note' sx={{textAlign:'right'}}>
        {date}  
        <br/>
        {author}      
      </Typography>
      </Box>

      <Link className={styles.subtitle} to={`/cabinet/posts/${_id}`}>
        {text}
      </Link>
    </Paper>
  )
}
