import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';

export const fetchPosts = createAsyncThunk('posts/fetchPosts', async () => {
    const { data } = await axios.get('/cabinet/posts?timestamp=${Date.now()}');
    return data;
  });

export const fetchUserPosts = createAsyncThunk('posts/fetchUserPosts', async () => {
    const { data } = await axios.get('/cabinet/posts/user/:id');
    return data;
  }); 
  
  const initialState = {
    posts: {
      items: [],
      status: 'loading',
    },
  };
  
  const postsSlice = createSlice({
    name: 'posts',
    initialState,
    reducers: {},
    extraReducers: {
      // отримання постів
      [fetchPosts.pending]: (state) => {
        state.posts.items = [];
        state.posts.status = 'loading';
      },
      [fetchPosts.fulfilled]: (state, action) => {
        state.posts.items = action.payload;
        state.posts.status = 'loaded';
      },
      [fetchPosts.rejected]: (state) => {
        state.posts.items = [];
        state.posts.status = 'error';
      },
      // отримання постів юзера
      [fetchUserPosts.pending]: (state) => {
        state.posts.items = [];
        state.posts.status = 'loading';
      },
      [fetchUserPosts.fulfilled]: (state, action) => {
        state.posts.items = action.payload;
        state.posts.status = 'loaded';
      },
      [fetchUserPosts.rejected]: (state) => {
        state.posts.items = [];
        state.posts.status = 'error';
      },
    },
  });
  

  export const postsReducer = postsSlice.reducer;