import React from 'react';
import { useParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from '../../axios';
import { checkRole } from '../../redux/slices/auth';
import { NewCarlogPage } from '../../components';

export const FullCarlog = () => {
  
  const [data, setData] = React.useState(null);
  const [isLoading, setLoading] = React.useState(true);
  const { id } = useParams();
  const role = useSelector(checkRole);


  React.useEffect(() => {
    axios
      .get(`/cabinet/carlogs/${id}`)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        alert('помилка при отриманні логу');
      });
  }, []);

  return (
    <NewCarlogPage 
    data={data} 
    isLoading={isLoading} 
    role={role}
    />
  );
};