const CarlogFields = [
    { name: "front", uName: "Перед"},
    { name: "right", uName: "Правий бік" },
    { name: "back", uName: "Зад"},
    { name: "left", uName: "Лівий бік"},
    { name: "salon", uName: "Cалон"},
    { name: "panel", uName: "Панель приборів"},
    { name: "oil", uName: "Мастило"},
    { name: "breaks", uName: "Гальма"},
    { name: "tyreDefects", uName: "дефекти шин"},
    { name: "outerDefects", uName: "зовнішні дефекти"},
    { name: "zapasPhotos", uName: "Фото запасних коліс"},
    { name: "itemPhotos", uName: "Фото розпірок"},
  ];
  
  const docsList = [
    { name: "fuelCard_1", uName: "Паливна карта DKV" },
    { name: "fuelCard_2", uName: "Tankpool24" },
    { name: "techPasport_1", uName: "Техпаспорт авто" },
    // { name: "techPasport_2", uName: "Техпаспорт причепу" },
    { name: "licence_1", uName: "Ліцензія" },
    // { name: "licence_2", uName: "Ліцензія" },
    { name: "sign", uName: "Печатка" }
  ];

  const docsMarshalUA = [
    { name: "fuelCard_1", uName: "Паливна карта WOG" },
    // { name: "fuelCard_2", uName: "Паливна карта_2" },
    { name: "techPasport_1", uName: "Техпаспорт авто" },
    { name: "techPasport_2", uName: "Техогляд авто" },
    { name: "insurance_1", uName: "Страховка (автоцивілка)" },
    { name: "insurance_2", uName: "Зелена карта" },
    { name: "licence_1", uName: "Сертифікат калібровки тахографа" },
    // { name: "licence_2", uName: "Ліцензія" },
    // { name: "sign", uName: "Сертифікат калібровки тахографа" }
  ];


  const docsTrailer = [
    { name: "techPasport_1", uName: "Техпаспорт причепу" },
    { name: "techPasport_2", uName: "Техогляд причепу" },
    { name: "insurance_1", uName: "Страховка (автоцивілка)" },
    { name: "insurance_2", uName: "Зелена карта" },
    { name: "licence_1", uName: "ФРЦ" },
  ];

  const toolsList = [
    { name: "tool_1", uName: "Домкрат" },
    { name: "tool_2", uName: "Балонний ключ" },
    { name: "tool_3", uName: "Шланг підкачки" },
    { name: "tool_4", uName: "Краби" },
    { name: "tool_5", uName: "М'ясорубка" },
  ];

  const toolsAvtovoz = [
    { name: "tool_1", uName: "Домкрат" },
    { name: "tool_2", uName: "Балонний ключ" },
    { name: "tool_3", uName: "Шланг підкачки" },
    { name: "tool_4", uName: "Краби" },
    { name: "tool_5", uName: "М'ясорубка" },
    { name: "tool_6", uName:'Трапи'},
    { name: "tool_7", uName:'Чохол на лебідку '},
    { name: "tool_8", uName:'Стаціонарний пульт до лебідки'},
    { name: "tool_9", uName:'Дистанційний пульт до лебідки'},
  ];

  const toolsLafeta = [
    { name:"tool_1", uName:'Опорне колесо'},
    { name:"tool_2", uName:'Трапи'},
  ];

  const ratingList = [
    { name: "lookRating", uName: "Оцінка стану салону" },
    { name: "fTyreRating", uName: "Передніх коліс" },
    { name: "bTyreRating", uName: "Задніх коліс" },
    { name: "axis_1", uName: "Вісь 1" },
    { name: "axis_1", uName: "Вісь 2" },
    { name: "axis_1", uName: "Вісь 3" },
  ];
  
  export { CarlogFields, docsList, ratingList, toolsList, toolsAvtovoz, toolsLafeta, docsMarshalUA, docsTrailer};