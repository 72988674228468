import { useState, useEffect} from 'react';
import { Link, redirect, useNavigate } from 'react-router-dom';
import { NavLink as NavLinkBase } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import { selectUserName, checkRole, logout, selectIsAuth, selectCompany } from '../../redux/slices/auth';

import styles from './Header.module.scss';

import { styled } from '@mui/material/styles';
import { Container, Box, Typography } from '@mui/material/';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ChecklistRtlOutlinedIcon from '@mui/icons-material/ChecklistRtlOutlined';
import UnfoldMoreDoubleOutlinedIcon from '@mui/icons-material/UnfoldMoreDoubleOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import LogoutIcon from '@mui/icons-material/Logout';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';


const NavLink = styled((props) => (
  <NavLinkBase {...props} end={props.to === "/"}>
    <StyledListItem open={props.open} label={props.label} icon={props.icon} />
  </NavLinkBase>
))(({ theme }) => ({
  textDecoration: "none",
  "&.active": {
    color: "#d32f2f",
  }
}));

const StyledListItem = (props)=>{
    return(
      <ListItemButton
      onClick={props.action}
      sx={{
        minHeight: 48,
        // px: 2.5,
        padding:'4px 8px'
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: '30px',
          justifyContent: 'center',
        }}
      >
        {props.icon}
      </ListItemIcon>
      <ListItemText primary={props.label} color={props.color} sx={{textWrap:'nowrap'}}/>
    </ListItemButton>
    )
  }


export const Header =()=>{
  const [navigation, setNavigation] = useState([]);
  const isAuth = useSelector(selectIsAuth);
  const company = useSelector(selectCompany);
  const userName = useSelector(selectUserName);
  const dispatch = useDispatch();
  const role = useSelector(checkRole);

  const onClickLogout = ()  => {
    if (window.confirm('Впевнені що хочете вийти?')) {
      redirect(`/`);
      window.localStorage.removeItem('token');     
      dispatch(logout());
    }
  };

  const managerPages = [
    {
      label: 'Здача авто',
      link: '/carlogs',
      icon: <><UnfoldMoreDoubleOutlinedIcon /><LocalShippingIcon /></>,
    },
    {
      label: 'Здача причепів',
      link: '/trailerlogs',
      icon: <><UnfoldMoreDoubleOutlinedIcon /><RvHookupIcon /></>,
      trailers: true,
    },
    {
      label: 'Поломки',
      link: '/posts',
      icon: <ChecklistRtlOutlinedIcon />,
    },
    {
      label: 'Водії',
      link: '/cabinet/users',
      icon: <PeopleOutlineIcon />,
    },
    {
      label: 'Авто',
      link: '/cabinet/cars',
      icon: <LocalShippingIcon />,
    },
    {
      label: 'Причепи',
      link: '/cabinet/trailers',
      icon: <RvHookupIcon />,
      trailers: true,
    },
    // {
    //   label: 'Статистика',
    //   link: '/dashboard',
    //   icon: <TroubleshootIcon />,
    // },
  ];
  const userPages = [
    {
      label: 'Поломки',
      link: '/posts',
      icon: <ChecklistRtlOutlinedIcon />
    },
    {
      label: 'Здачі авто',
      link: '/carlogs',
      icon: <UnfoldMoreDoubleOutlinedIcon />
    },
    {
      label: 'Здачі причепів',
      link: '/trailerlogs',
      trailers: true,
      icon: <UnfoldMoreDoubleOutlinedIcon />
    },    
  ];

  
  var hasTrailers = ()=>{
    if(company.find((item)=>item==='Marshal UA'||item==='Автовози')){
      console.log('has trailers')
      return true;
    }else{
      return false
    }
  }


  useEffect(()=>{

    if(role==='driver'){
      return !hasTrailers()?setNavigation(userPages.filter((item)=>!item.trailers)):setNavigation(userPages)
    }else if(role==='manager'||role==='admin'||role==='mechanic'){
      return !hasTrailers()?setNavigation(managerPages.filter((item)=>!item.trailers)):setNavigation(managerPages)
    }
  },[company, role])


    return(
        isAuth?
        <AppBar position="static" color='inherit' id='header'>
          <Container maxWidth="xl">
            <Toolbar disableGutters>
                  {/* left*/}
                  <LogoButton />
                  
                  <Box sx={{ flexGrow: 1 }}> 
                    <Typography classes={{root: styles.username}} variant="p"
                      sx={{ 
                        flexGrow: 0, 
                        display:'flex'
                        }}
                    >
                    {userName}
                    </Typography>
                    <Typography  classes={{root: styles.company}} variant="p"
                      sx={{ 
                        flexGrow: 0, 
                        display:'flex'
                        }}
                    >
                      {company.length>1?role:company}
                    </Typography>
                  </Box>
                  
                  {/* navigation on desktop */}
                  <List sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-start', alignItems:'center'}}>                   
                    {navigation.map((page) => (
                        <ListItem key={page.label} disablePadding sx={{ display: 'block'}}>
                        <NavLink 
                            to={page.link} 
                            icon={page.icon}
                            key={page.label}
                            color={page.color || 'primary'}
                            label={page.label} 
                            onClick={page.action}
                            />
                        </ListItem>
                    ))}
                    <Divider orientation="vertical" sx={{ height:'25px !important'}}/>
                    <ListItem disablePadding>
                        <NavLink
                          color='error'
                          icon={<LogoutIcon />}
                          onClick={onClickLogout}
                          label='Вийти' 
                        />
                    </ListItem> 
                  </List>

                  <BurgerMenu navigation={navigation} logout={onClickLogout} />

            </Toolbar>
          </Container>
        </AppBar>
        :null   

    )
}

const BurgerMenu = ({navigation, logout})=>{
  const [anchorElNav, setAnchorElNav] = useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  }; 

  return(
    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
    <IconButton
      size="large"
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={handleOpenNavMenu}
      color="inherit"
    >
      <MenuIcon />
    </IconButton>
    <Menu
      id="menu-appbar"
      anchorEl={anchorElNav}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={Boolean(anchorElNav)}
      onClose={handleCloseNavMenu}
      sx={{
        display: { xs: 'block', md: 'none' },
      }}
    >
        <List sx={{display:'flex-column', justifyContent: 'flex-start', alignItems:'center'}}>
        {navigation.map((page) => (
            <ListItem key={page.label} disablePadding sx={{ display: 'block'}}>
            <NavLink 
                to={page.link} 
                icon={page.icon}
                key={page.label}
                onClick={page.action||handleCloseNavMenu}
                label={page.label}  
                />
            </ListItem>
        ))}
        </List>
        <Divider orientation="vertical" sx={{ height:'1px !important'}}/>
          <ListItem disablePadding>
              <NavLink
                color='error'
                icon={<LogoutIcon />}
                onClick={logout}
                label='Вийти' 
              />
        </ListItem> 
    </Menu>
    </Box> 
  )
}

const LogoButton = ()=>{
  return(
    <Box sx={{ flexGrow: 0, padding:1}}>              
        <Link to="/home" >
          <Box
            id='logo'
            sx={{            
              width:'55px',
              height:'55px',
              background: 'white', 
              borderRadius: '9999px', 
              border: '1.70px #1D3869 solid',
              backgroundImage: 'url("/logo.svg")', 
              backgroundSize: '65%', 
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          >          
          </Box>
      </Link>
    </Box>
  )
}