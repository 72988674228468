import * as React from 'react';
import { useSelector } from 'react-redux';
import { HomeManager, HomeUser, HomeAdmin, NoMatch } from '.';
import { CircularProgress } from '@mui/material';


export const Home=()=>{
  const {role, status} = useSelector(state=>state.auth);
  // const role = 'admin';

  
  if(status==='loading'){
    return <CircularProgress color="inherit" />
  }else if(status==='error'){
    return <NoMatch err='проблема завантаження даних' />
  }
  
  const whatRole = (role)=>{
    switch(role){
      case 'driver':
        return <HomeUser />
      case 'manager':
        return <HomeManager />
      case 'mechanic':
        return <HomeManager />
      case 'admin':
        return <HomeAdmin />
      default:
        return <NoMatch err='помилка доступу' />
    }
  }
  return whatRole(role)
}