import React from "react";
import styles from './Steps.module.scss';
import { CarlogFields } from "../carlogComponents/CarLogFields";
import { ImagePreview } from "../../../utils/ImagePreview";
import { Box, Grid, Typography } from "@mui/material";

export const Step1 = ({ handleChangeImageUrl, step})=>{


  return (
    <div>
      <Box mb={3}>
      <Typography                 
        variant="h5"
        classes={{root: styles.title}}
        >
        {`0${step}. Зовнішній вигляд`}
      </Typography>
      <Typography variant="p"
      classes={{root: styles.subtitle}}
      > Завантажте будь ласка фото, для підтвердження стану в момент
        прийому
      </Typography> 
      </Box> 
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} >
        {CarlogFields.slice(0, 4).map((field) => {
          return (
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3} key={field.name}> 
          <ImagePreview 
              inputName={field.name}
              labelText={field.uName}                         
              onChangeImageUrl={(url) => handleChangeImageUrl(url, field.name)}
              />

        </Grid>
          );
        })}
      </Grid>
      </Box>
    </div>    
  );
}