import React from "react";
import { CarlogFields } from "../carlogComponents/CarLogFields";
import { ImagesManyPreview } from "../../../utils/ImagePreview";
import { Typography, Box, Radio, RadioGroup, FormControlLabel, FormControl,} from "@mui/material";
import styles from './Steps.module.scss';


export const TrailerStep4 = ({step, handleChangeImagesUrls, zapasNumber, setZapasNumber, type, item_1, setItem_1})=>{


  return (
    <>
          <Box className='block'>
          <Typography                 
          variant="h5"
          classes={{root: styles.title}}
          >
          {`0${step}. Кількість запасних коліс`}
          </Typography>
        </Box>
        <br />
        <FormControl>
        <RadioGroup
          row
          id='zapaska'
          aria-labelledby="radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={ (e)=> setZapasNumber(e.target.value)}
        >
          <FormControlLabel value="0" control={<Radio />} label="0" />
          <FormControlLabel value="1" control={<Radio />} label="1" />
          <FormControlLabel value="2" control={<Radio />} label="2" />
        </RadioGroup>
      </FormControl>      
        <br />
        <br />
        {zapasNumber!=='' && zapasNumber!=='0'?
          <>
          <Typography variant="subp">{zapasNumber==='1'? 'Фото однієї запаски':'Фото 2-х запасок'}</Typography>       
          <ImagesManyPreview
                  number='можна вибрати до 2-х фото з галереї'
                  inputName={CarlogFields[10].name}
                  labelText={CarlogFields[10].uName}
                  onChangeImagesUrls={(urls) => handleChangeImagesUrls(urls, CarlogFields[10].name)} 
          />
          </>
        :null
        }
        {type==='Рефрижиратор'? 
        <React.Fragment>
        <Box className='block'>
          <Typography                 
          variant="h5"
          classes={{root: styles.title}}
          >
          Кількість розпірок
          </Typography>
        </Box>
        <br />
        <FormControl>
        <RadioGroup
          id='ref-tools'
          row
          aria-labelledby="radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={ (e)=> setItem_1(e.target.value)}
        >
          <FormControlLabel value="0" control={<Radio />} label="0" />
          <FormControlLabel value="1" control={<Radio />} label="1" />
          <FormControlLabel value="2" control={<Radio />} label="2" />
          <FormControlLabel value="3" control={<Radio />} label="3" />
          <FormControlLabel value="4" control={<Radio />} label="4" />
          <FormControlLabel value="5" control={<Radio />} label="5" />
        </RadioGroup>
        </FormControl>      
        <br />
        <br />
        {item_1!=='' && item_1!=='0'?
          <Box className='block'>
          <Typography variant="subp">Розпірки</Typography>       
          <ImagesManyPreview
                  number='можна вибрати до 3-х фото з галереї'
                  inputName={CarlogFields[11].name}
                  labelText={CarlogFields[11].uName}
                  onChangeImagesUrls={(urls) => handleChangeImagesUrls(urls, CarlogFields[11].name)} 
          />
          </Box>
        :null
        }
        </React.Fragment>
         : null}
    </>
  );
}

