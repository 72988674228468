import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#1D3869",
    },
  },
  typography: {
    fontFamily:'Montserrat, sans-serif',
    h1:{
      fontFamily:'Montserrat, sans-serif',
      fontWeight:700,
      fontSize:'48px',
    },
    h2:{
      fontFamily:'Montserrat, sans-serif',
      fontWeight:700,
      fontSize:'36px',
    },
    h5:{
      fontFamily:'Montserrat, sans-serif',
      fontWeight:700,
      fontSize:'22px',
    },
    p:{
      fontFamily:'Montserrat, sans-serif',
      fontWeight:400,
      fontSize:'18px',
      "@media (max-width: 423px)":{
        fontSize:'16px',
      }
    },
    subp:{
      fontFamily:'Montserrat, sans-serif',
      fontWeight:500,
      fontSize:'18px',
    },
    note:{
      fontFamily:'Montserrat, sans-serif',
      fontWeight:400,
      fontSize:'14px',
    },
    button: {
      textTransform: "none",
      fontWeight: 400,
      fontSize:"14px",
      margin: '3px 3px'
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "15px", 
        },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            border:"none",
            padding:"8px 22px"
          },          
          props: { variant: "outlined" },
          style: {
            border:"0.5px solid #fff",
            border:"none",
            "&hover":{
              border:"0.5px solid #1D3869",
              padding:"8px 22px"
            }
          },
        },
      ],
    }
  },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            backgroundColor: "white",
            borderRadius: "15px",
          },
          "& .MuiInputBase-input": {
            backgroundColor: "#fff",
            color: "#1D3869",
            padding: "18px 37px",
            border: "1px solid #fcfcfc66",
            borderRadius: "15px"
          },
          "& .MuiInputBase-input:hover": {
            backgroundColor: "#fff",
            color: "#1D3869",
            border: "1px solid #fcfcfc66",
            borderRadius: "15px"
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "15px",
            borderColor: "#fcfcfc66",
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderRadius: "15px",
            borderColor: "none",
            padding:"0px",
            top:'0px',            
          },
          "& .MuiOutlinedInput-notchedOutline legend": {
             display: "none",
            },
          // "&  .MuiTableCell-root .MuiTableCell-body":{
          //   borderBottom: "none",
          //   padding: "6px",
          //   color:"#142749",
          // }
        },
      },
    },
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
    "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    
  ],
});
