import React from 'react';
// import { useParams } from 'react-router-dom';
import axios from '../../axios';

import { Grid, Typography } from "@mui/material";
import { FormatedTime, PieChart } from './charts';


export const Dashboard = ({company}) => {
    const [data, setData] = React.useState();
    const [isLoading, setLoading] = React.useState(true);


    React.useEffect(() => {
      axios
        .get(`/dashboard?company=${company}`)
        .then((res) => {
          setData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          alert('помилка при завантаженні даних');
        });
    }, [company]);
  
    if (isLoading) {
      return 'is loading'
    }
    const {
        totalNumber,
        avarageTimeToFirstAction
    } = data;

    return (
        <Grid container justifyContent="center" mt={5} mb={5}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mb={5} p={3}>
                <Typography variant="subp">
                    {company}
                    <br/>
                    {`Загальна кількість поломок: ${totalNumber}`}
                </Typography>
                <br/>
                <Typography variant="subp">
                   <FormatedTime description='Сер.час до першої взаємодії:' time={avarageTimeToFirstAction} />
                </Typography>
        </Grid>                  
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} p={1}> 
                <PieChart data={data.urgency1}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} p={1}> 
                <PieChart data={data.urgency2}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} p={1}> 
                <PieChart data={data.urgency3}/>    
            </Grid>
        </Grid>
    )  
}