import * as React from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFreeTrailers } from '../../../redux/slices/trailers';
import { ChooseCar } from '../carlogComponents/Choose-car';




export const TrailerStep0 = ({ setChosenTrailerNumber, chosenTrailerId, setChosenTrailerId, setChosenTrailerType})=>{
    
    const dispatch = useDispatch();
    const { data: freeTrailersList } = useSelector((state) => state.trailers);
  
    const handleChange = (event) => {
      const selectedTrailerId = event.target.value;
      const selectedTrailer = freeTrailersList.find(trailer => trailer._id === selectedTrailerId);
  
      if (selectedTrailer) {
        setChosenTrailerId(selectedTrailerId);
        setChosenTrailerNumber(selectedTrailer.trailerNumber);
        setChosenTrailerType(selectedTrailer.type);
      }
    };


  React.useEffect(() => {
    dispatch(fetchFreeTrailers());
  }, []);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={10} md={10} lg={8} xl={8}>
        <ChooseCar 
          title='Причіп' 
          data={freeTrailersList} 
          id={chosenTrailerId}
          handleChange={handleChange}
        />
      </Grid>
    </Grid>
  );
};