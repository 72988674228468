import React from 'react';
import { Link } from 'react-router-dom';
import styles from "./CarPage.module.scss";
import { Grid, Container, Button }from '@mui/material';
import { Title, Posts } from './newComponents';
import  TableTemplate  from '../Table';
import { headCells } from './headCells';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { FormarttedDate } from '../../utils';


const applyType = (value) => {  
  switch (value) {
    case 'приймаю':
      return (
          <>
            {value}
            <KeyboardDoubleArrowDownIcon sx={{ color: '#d32f2f', height: '15px'}} />
          </>
      );
    case 'здаю':
      return (
        <>
          {value}
          <KeyboardDoubleArrowUpIcon sx={{ color: 'blue', height: '15px'}} />
        </>
      );
    default:
      return null;
  }
};

const LogButton = (value, id, slug)=>{
  return(
    <Link to={`/cabinet/${slug}/${id}`}>
      <Button variant='outlined' size='small'>
        {applyType(value)}
      </Button>
    </Link>  
  )
}


export const CarPage = ({data}) => {

  const fields ={
    company:data.car.company,
    carNumber:data.car.carNumber,
    carBrand:data.car.brand,
    username: data.car.user?`${data.car.user.fName} ${data.car.user.sName}`:`без водія`,
  }
  const posts = data.car.posts;
  const carlogs = data.carlogs;

  function createData(item) {
    const { type, createdAt, user, _id} = item;
    const { fName, sName } = user;
    const username = `${fName} ${sName}`
    const button = LogButton(type, _id, 'carlogs');
    const date = <FormarttedDate key={_id} date={createdAt} />

    return { 
      button, 
      username,
      date,
    };
  }

  function createPostData (item){
    const { _id, text, urgency, status, user, createdAt, } = item;
    const { fName, sName, role } = user;
    const author = `${fName} ${sName}`
    const date = <FormarttedDate date={createdAt} />

    return {
      _id,
      text,
      urgency,
      status,
      date,
      author,
      role
    }
  }

  const rows = carlogs.map(item=>createData(item));
  const postData = posts.map(item=>createPostData(item));

  return (
    <Grid container mt={3}>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <Title data={data} fields={fields}/>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Container classes={{root: styles.container}}>
            <Posts posts={postData} title='Поломки'/>
          </Container>
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <Container classes={{root: styles.container}}>
            <TableTemplate data={rows} headCells={headCells} />
          </Container>
      </Grid>
    </Grid>
  );
};

export const TrailerPage = ({data}) => {
  console.log(data.trailer.trailerNumber)

  const fields ={
    company:data.trailer.company,
    carNumber:data.trailer.trailerNumber,
    carBrand:data.trailer.type,
    username: data.trailer.user?`${data.trailer.user.fName} ${data.trailer.user.sName}`:`без водія`,
  }
  const posts = data.trailer.posts;
  const trailerlogs = data.trailerlogs;

  function createData(item) {
    const { type, createdAt, user, _id} = item;
    const { fName, sName } = user;
    const username = `${fName} ${sName}`
    const button = LogButton(type, _id, 'trailerlogs');
    const date = <FormarttedDate key={_id} date={createdAt} />

    return { 
      button, 
      username,
      date,
    };
  }

  function createPostData (item){
    const { _id, text, urgency, status, user, createdAt, } = item;
    const { fName, sName, role } = user;
    const author = `${fName} ${sName}`
    const date = <FormarttedDate date={createdAt} />

    return {
      _id,
      text,
      urgency,
      status,
      date,
      author,
      role
    }
  }

  const rows = trailerlogs.map(item=>createData(item));
  const postData = posts.map(item=>createPostData(item));

  return (
    <Grid container mt={3}>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <Title data={data} fields={fields}/>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Container classes={{root: styles.container}}>
            <Posts posts={postData} title='Поломки'/>
          </Container>
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <Container classes={{root: styles.container}}>
            <TableTemplate data={rows} headCells={headCells} />
          </Container>
      </Grid>
    </Grid>
  );
};