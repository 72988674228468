import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from "./PhotoBlock.module.scss";
import { Box, Paper, Typography }from '@mui/material';
import Fullscreen from '@mui/icons-material/Fullscreen';
import Skeleton from "@mui/material/Skeleton";

export const PhotoBlock = ({ url, label}) => {
    const [isVisible, setVisible] = useState(false);
    const [isHovered, setIsHovered] = useState(false);


    const imgUrl = `${process.env.REACT_APP_API_URL}${url}`
  
    function handleMouseEnter() {
      setIsHovered(true);
      setVisible(true);
    }
  
    function handleMouseLeave() {
      setIsHovered(false);
      setVisible(false);
    }
  
  
    return (
      <>
      <Typography variant='note'>
        {label?<p>{label}</p>:null}
      </Typography>
        <Box
          className={styles.photoBox}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          >
          {isVisible && (
            <Link to={imgUrl} target="_blank" className={styles.iconLink}>
              <Fullscreen className={styles.icon} />
            </Link>
          )}
          <div className={styles.photoBox}>
            <img
              src={`${process.env.REACT_APP_API_URL}${url}`}
              className={styles.photoCarlog}
              alt=""
            />
            {isHovered && <div className={styles.overlay}></div>}
          </div>
        </Box>
      </>
    );
  };

export const PhotoGalery = ({items, isLoading})=>{
    return(
      <Paper className={styles.infoCard}>
        {(isLoading ? [...Array(5)] : items).map((url, index) => (
          <React.Fragment key={index}>
              {isLoading ? (
                <Skeleton variant="rounded" height={150} width='100%' sx={{marginBottom:'20px', borderRadius:'15px'}} />
              ) : (
                <PhotoBlock url={url} />
              )}
          </React.Fragment>
        ))}
        </Paper>
    )
  }