import React from "react";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export function ChooseCar({ data, title, id, handleChange}) {
  return (
      <FormControl sx={{ m: 1, width: 300, textAlign: 'center' }}>
        <InputLabel htmlFor="name-label">{title}</InputLabel>
        <Select
          labelId="name-label-"
          id={id}
          value={id}
          onChange={handleChange}
          input={<OutlinedInput label="Name" />}
          MenuProps={{
          disableScrollLock: true,
          }}
        >
        {data ? data.map((car) => (
          car.active? 
          <MenuItem key={car.carNumber||car.trailerNumber} value={car._id} data-car-number={car.carNumber||car.trailerNumber}>
            {car.carNumber||(car.type.length >= 5? car.type.substr(0, 5)+'.':car.type)+"  "+car.trailerNumber}
          </MenuItem>
          : 
          []
        ))
        : <MenuItem >
            Немає активних авто
          </MenuItem>}
        </Select>
      </FormControl>
  )  
}