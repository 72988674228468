import React from "react";
import RatingLine from "../carlogComponents/RatingLine";
import { CarlogFields } from "../carlogComponents/CarLogFields";
import { ImagesManyPreview } from "../../../utils/ImagePreview";
import { TextField, Typography, Box} from "@mui/material";
import styles from './Steps.module.scss';


export const Step2 = ({step, handleChangeImagesUrls, lookNote, setLookNote, lookRating, setLookRating})=>{  

  return (
    <>    
  
      <Typography                 
        variant="h5"
        classes={{root: styles.title}}
        >
        {`0${step}. Стан салону`}
      </Typography>
      <Typography variant="p" classes={{root: styles.subtitle}}>
        Зробіть 3-4 фото салону з різних ракусрів
      </Typography> 
      <Box className='block'>  
      <ImagesManyPreview
              number='можна вибрати до 4-х фото з галереї'
              inputName={CarlogFields[4].name}
              labelText={CarlogFields[4].uName}
              onChangeImagesUrls={(urls) => handleChangeImagesUrls(urls, CarlogFields[4].name)} 
      />
      </Box>
      <RatingLine
        number={0} 
        value={lookRating}
        onChangeRatingValue={setLookRating}
      />
      <TextField
        classes={{ root: styles.p }}
        variant="outlined"
        placeholder="Зауваження якщо є"
        value={lookNote}
        onChange={ (e)=> setLookNote(e.target.value)}
        fullWidth
      />

    </>
  )
}

     
