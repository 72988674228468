import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';

export const fetchCarlogs = createAsyncThunk('carlogs/fetchCarlogs', async () => {
    const { data } = await axios.get('/cabinet/carlogs?timestamp=${Date.now()}');
    return data;
  }); 

export const fetchUserCarlogs = createAsyncThunk('carlogs/fetchUserCarlogs', async () => {
    const { data } = await axios.get('/cabinet/carlogs/user/:id');
    return data;
  }); 
  
  const initialState = {
    carlogs: {
      items: [],
      status: 'loading',
    },
  };
  
  const carlogSlice = createSlice({
    name: 'carlogs',
    initialState,
    reducers: {},
    extraReducers: {
      // отримання carlogs
      [fetchCarlogs.pending]: (state) => {
        state.carlogs.items = [];
        state.carlogs.status = 'loading';
      },
      [fetchCarlogs.fulfilled]: (state, action) => {
        state.carlogs.items = action.payload;
        state.carlogs.status = 'loaded';
      },
      [fetchCarlogs.rejected]: (state) => {
        state.carlogs.items = [];
        state.carlogs.status = 'error';
      },
      // отримання carlogs юзера
      [fetchUserCarlogs.pending]: (state) => {
        state.carlogs.items = [];
        state.carlogs.status = 'loading';
      },
      [fetchUserCarlogs.fulfilled]: (state, action) => {
        state.carlogs.items = action.payload;
        state.carlogs.status = 'loaded';
      },
      [fetchUserCarlogs.rejected]: (state) => {
        state.carlogs.items = [];
        state.carlogs.status = 'error';
      },
    },
  });
  

  export const carlogReducer = carlogSlice.reducer;