import React from "react";
import {Box, Typography} from '@mui/material/';

export const Footer = ()=>{
    const year = new Date().getFullYear();
    return (
      <Box
        id='footer'
        sx={{
            flexGrow:1,
            display: "flex",
            backgroundColor: '#1d3869',
            textAlign: 'center',
            color: 'white',
            width:'100%',
            justifyContent:"center",
            marginTop: "auto",
            p: '14px',
            // p:1,
        }}
      >
        <Typography variant="note">v.{process.env.REACT_APP_API_BUILD_VERSION} Marshal Trans ⓒ {year}</Typography>
      </Box>
    );
}