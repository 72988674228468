import React, { useState } from 'react';
import styles from "./NewCarlogPage.module.scss";
import { Grid, Container }from '@mui/material';
import { Title, Raiting, Lists, Outlook, TechCondition, ConditionalBlock } from './newComponents';
import { CommentsBlock } from '../CommentsBlock';
import axios from '../../axios';

function refreshPage() {
  window.location.reload(false);
}

export const NewCarlogPage = ({data, isLoading, role}) => {
  const [comment, setComment]=useState('');

  if (isLoading) {
    return <p>Loading...</p>
  }
  const id = data._id;
  const company = data.company;
  const fields ={
    type:data.type,
    carNumber:data.car.carNumber,
    carBrand:data.car.brand,
    username:`${data.user.fName} ${data.user.sName}`,
    date: data.createdAt,
  }
  const outLook = [
    { url: data.outLook.front, label:'cпереду',},
    { url: data.outLook.right, label:'зправа',},
    { url: data.outLook.left, label:'зліва',},
    { url: data.outLook.back, label:'ззаду',},
    { url: data.outLook.salon, label:'Cалон',},
  ]
  const ratings = [
    { value: data.outLook.lookRating, label:'чистота'},
    { value: data.tyreConditions.fTyreRating, label:'З.Шини'},
    { value: data.tyreConditions.bTyreRating, label:'П.шини'},
    { value: data.tyreConditions.zapasNumber, label:'запаски'},
    { value: data.inventory.item_2, label:'ремені'},
  ]
  const panel = [
      { url:data.techConditions.panel, label:'Панель', },
      { url:data.techConditions.oil, label:'Мастило', },
      { url:data.techConditions.breaks, label:'Гальма', },
  ]
  const defects =[
    { url: data.tyreConditions.tyreDefects, label: "Фото дефектів шин",},
    { url: data.tyreConditions.outerDefects, label: "Фото зовнішніх дефектів",},
  ]
  const note = [
    { text: data.outLook.lookNote, label: "Зауваження до салону" },
    { text: data.tyreConditions.tNote, label: "Зауваження до технічного стану" },
  ]
  const docsMarshalUA = [
    { name: "fuelCard_1", value: data.docComplectation.fuelCard_1, label: "Паливна карта WOG" },
    { name: "techPasport_1",  value: data.docComplectation.techPasport_1, label: "Техпаспорт авто" },
    { name: "techPasport_2",  value: data.docComplectation.techPasport_2, label: "Техогляд авто" },
    { name: "insurance_1", value: data.docComplectation.licence_1, label: "Сертифікат калібровки тахографа" },    
    { name: "insurance_2",  value: data.docComplectation.insurance_1, label: "Страховка (автоцивілка)" },
    { name: "licence_1", value: data.docComplectation.insurance_2, label: "Зелена карта" }
  ];
  const docsMarshalTrans = [
    { name: "fuelCard_1", value: data.docComplectation.fuelCard_1, label: "Паливна карта DKV"},
    { name: "fuelCard_2", value: data.docComplectation.fuelCard_2, label: "Tankpool24"},
    { name: "techPasport_1", value: data.docComplectation.techPasport_1, label: "Техпаспорт авто"},
    { name: "licence_1", value: data.docComplectation.licence_1, label: "Ліцензія"},
    { name: "sign", value: data.docComplectation.sign, label: "Печатка" }
  ];
  const defInventory = [
    { name: "tool_1", value: data.inventory.tool_1, label: "Домкрат" },
    { name: "tool_2", value: data.inventory.tool_2, label: "Балонний ключ" },
    { name: "tool_3", value: data.inventory.tool_3, label: "Шланг підкачки" },
    { name: "tool_4", value: data.inventory.tool_4, label: "Краби" },
    { name: "tool_5", value: data.inventory.tool_5, label: "М'ясорубка" },
  ];
  const toolsAvtovoz = [
    { name: "tool_1", value: data.inventory.tool_1, label: "Домкрат" },
    { name: "tool_2", value: data.inventory.tool_2, label: "Балонний ключ" },
    { name: "tool_3", value: data.inventory.tool_3, label: "Шланг підкачки" },
    { name: "tool_4", value: data.inventory.tool_4, label: "Краби" },
    { name: "tool_5", value: data.inventory.tool_5, label: "М'ясорубка" },
    { name: "tool_6", value: data.inventory.tool_6, label: 'Трапи' },
    { name: "tool_7", value: data.inventory.tool_7, label: 'Чохол на лебідку ' },
    { name: "tool_8", value: data.inventory.tool_8, label: 'Стаціонарний пульт до лебідки' },
    { name: "tool_9", value: data.inventory.tool_9, label: 'Дистанційний пульт до лебідки' },
];


  let inventory = company!=='Автовози'? defInventory:toolsAvtovoz;
  let docs = company !=='Marshal Trans'? docsMarshalUA:docsMarshalTrans;

  const addComment = async()=>{
    try {
      console.log(comment)
      const {data} = await axios.post(`/comment?carlog=${id}`, {comment})
      alert('додали комент');
      refreshPage();
    } catch (err) {
      console.error(err);
      alert('не вдалося додати комент');
    }
  }

  return (
    <Grid container mt={3}>
      <Grid xs={12} sm={12} md={3} lg={3} xl={3}>
          <Title data={data} fields={fields}/>
          <Raiting ratings={ratings} />
          {role!=='driver'? 
            <CommentsBlock 
              items={data.comments} 
              isLoading={isLoading} 
              setComment={setComment} 
              onSubmit={addComment}
              role={role}
            />
          : null}
      </Grid>
      <Grid xs={12} sm={12} md={5} lg={5} xl={5}>
          <Outlook outLook={outLook} title='Зовнішній вигляд'/>
          <TechCondition panel={panel} title='Технічний стан' />
      </Grid>
      <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
      <Container classes={{root: styles.container}} sx={{display:'flex', justifyContent:'left'}}>
              <Lists 
                list={docs}
                title='Докумети' 
                id={data._id}
                slug='carlog'
                role={role}
              />
              <Lists 
                list={inventory}
                title='Інвентар' 
                id={data._id}
                slug='carlog'
                role={role}
              />
      </Container>
          <Container classes={{root: styles.container}}>
            <ConditionalBlock note={note[0]} title='Зауваження' />
            <ConditionalBlock data={defects[0]} note={note[1]} isOk={false} />
            <ConditionalBlock data={defects[1]} isOk={false}/>
          </Container>
      </Grid>
    </Grid>
  );
};

export const NewTrailerlogPage = ({data, isLoading, role})=>{    
  const [comment, setComment]=useState('');
  if (isLoading) {
    return <p>Loading...</p>
  }
  const trailerType = data.trailer.type;
  const id = data._id;
  const company = data.company;
  const fields ={
    type:data.type,
    carNumber:data.trailer.trailerNumber,
    carBrand:trailerType,
    username:`${data.user.fName} ${data.user.sName}`,
    date: data.createdAt,
  }

  const outLook = [
    { url: data.outLook.front, label:'cпереду',},
    { url: data.outLook.right, label:'зправа',},
    { url: data.outLook.left, label:'зліва',},
    { url: data.outLook.back, label:'ззаду',},
    { url: data.outLook.salon, label:'Cалон',},
  ]
  const ratings = [
    { value: data.tyreConditions.axis_1, label:'1-ша вісь'},
    { value: data.tyreConditions.axis_2, label:'2-га вісь'},
    { value: data.tyreConditions.axis_3, label:'3-тя вісь'},
    { value: data.tyreConditions.zapasNumber, label:'запаски'},
    { value: data.inventory.item_1, label:'розпорки'},
    { value: data.inventory.item_2, label:'ремені'},
  ]

  const zapas = { url: data.tyreConditions.zapasPhotos, label: "Фото запасок", }
  const defect = { url: data.tyreConditions.tyreDefects, label: "Дефекти шин", }

  const note = [
      { text: data.outLook.lookNote, label: "Зауваження до технічного стану" },
      { text: data.tyreConditions.tNote, label: "Зауваження до технічного стану" }
  ]
  const amunition =  { url: data.inventory.itemPhotos, label: "Фото розпірок" }
  const inventoryLafeta = [
    { name:'tool_1',value: data.inventory.tool_1, label:'Опорне колесо'},
    { name:'tool_2',value: data.inventory.tool_2, label:'Трапи'},
  ]

  const docsMarshalUA = [
    { name: "techPasport_1",  value: data.docComplectation.techPasport_1, label: "Техпаспорт авто" },
    { name: "techPasport_2",  value: data.docComplectation.techPasport_2, label: "Техогляд авто" },
    { name: "insurance_1", value: data.docComplectation.insurance_1, label: "Страховка (автоцивілка)" },    
    { name: "insurance_2",  value: data.docComplectation.insurance_2, label: "Зелена карта" },
    { name: "licence_1", value: data.docComplectation.licence_2, label: "ФРЦ" , ref:true}
  ];

  let inventory = company!=='Автовози'? null:inventoryLafeta;
  let docs = trailerType!=='Рефрижиратор'?docsMarshalUA.filter((docs)=>docs.ref!==true):docsMarshalUA;

  const addComment = async()=>{
    try {
      console.log(comment)
      const {data} = await axios.post(`/comment?carlog=${id}`, {comment})
      alert('додали комент');
      refreshPage();
    } catch (err) {
      console.error(err);
      alert('не вдалося додати комент');
    }
  }

  return(
    <Grid container mt={3}>
      <Grid xs={12} sm={12} md={3} lg={3} xl={3}>
          <Title data={data} fields={fields}/>
          <Raiting ratings={ratings} />
          {role!=='driver'? 
            <CommentsBlock 
              items={data.comments} 
              isLoading={isLoading} 
              setComment={setComment} 
              onSubmit={addComment}
              role={role}
            />
          : null}
      </Grid>
      <Grid xs={12} sm={12} md={5} lg={5} xl={5}>
          <Outlook outLook={outLook} title='Зовнішній вигляд'/>
      </Grid>
      <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
      <Container classes={{root: styles.container}} sx={{display:'flex', justifyContent:'left'}}>
          <Lists 
                list={docs}
                title='Докумети' 
                id={data._id}
                slug='trailerlog'
                role={role}
              />
          {company!=="Автовози"? null:
            <Lists 
                  list={inventory}
                  title='Інвентар' 
                  id={data._id}
                  slug='trailerlog'
                  role={role}
                />
          }
      </Container>
      <Container classes={{root: styles.container}}>
        <ConditionalBlock data={zapas} isOk={true} />
        <ConditionalBlock note={note[0]} title='Зауваження'/>
        <ConditionalBlock data={defect}/>
        <ConditionalBlock note={note[1]}/>        
        {trailerType==='Рефрижиратор'? <ConditionalBlock data={amunition} title='Розпірки' isOk={true} /> : null}
      </Container>
      </Grid>
    </Grid>
  )

}