import Container from "@mui/material/Container";
import { Routes, Route }from 'react-router-dom';
import React, { useState } from 'react';

import { Header, Footer } from "./components";
import { 
        Home,
        FullPost,
        AddUser,
        AddPost,
        AddPostTrailer,
        Login,
        AddCar,
        AddTrailer,
        TakeCar,
        DropCar,
        FullCarlog,
        FullTrailerLog,
        Users,
        Cars,
        Trailers,
        Carlogs,
        TrailerLogs,
        Posts,
        DropTrailer,
        TakeTrailer,
        NoMatch,
        Dashboard,
        Car,
        Trailer
      } from "./pages";
import { useDispatch, useSelector} from "react-redux";
import { fetchAuthMe, selectIsAuth } from "./redux/slices/auth";
import { CircularProgress } from "@mui/material";


function App() {
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);
  const [isLoading, setLoading]= useState(true)


  React.useEffect(()=>{
    try {
      setLoading(true)
      dispatch(fetchAuthMe());
      if (isAuth && ('token' in window.localStorage)) {
        setLoading(false)
      } 
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  },[dispatch, 'token' in window.localStorage])


    if(isLoading){
      return <CircularProgress sx={{height:'50px', width:'50px', position:'absolute', top: '50%', left: '50%'}} />
    }

  return (
    <>
      <div 
        style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '95vh', 
      }}
      >
        <Header />
        <Content isAuth={isAuth}/>
      </div>
    <Footer />
    </>
  );
}

export default App;

const Content=({isAuth})=>{

  return(
    <Container
    maxWidth="lg"
    style={{
      flexGrow: 1, 
      padding: 0,
      maxWidth:'1340px' 
    }}
  >
  <Routes basename="/">
  {isAuth && ('token' in window.localStorage)? (
        <>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/cabinet/add-new-user" element={<AddUser />} />
          <Route path="/cabinet/add-car" element={<AddCar />} />
          <Route path="/cabinet/add-trailer" element={<AddTrailer />} />
          <Route path="/cabinet/add-post" element={<AddPost />} />
          <Route path="/cabinet/add-post/car/:id" element={<AddPost />} />
          <Route path="/cabinet/add-post/trailer" element={<AddPostTrailer />} />
          <Route path="/cabinet/add-post/trailer/:id" element={<AddPostTrailer />} />
          <Route path="/cabinet/drop-car" element={<DropCar />} />
          <Route path="/cabinet/take-car" element={<TakeCar />} />
          <Route path="/cabinet/drop-trailer" element={<DropTrailer />} />
          <Route path="/cabinet/take-trailer" element={<TakeTrailer />} />
          <Route path="/home" element={<Home />} />
          <Route path="/carlogs" element={<Carlogs />} />
          <Route path="/trailerlogs" element={<TrailerLogs />} />
          <Route path="/posts" element={<Posts />} />
          <Route path="/cabinet/posts/:id" element={<FullPost />} />
          <Route path="/cabinet/carlogs/:id" element={<FullCarlog />} />
          <Route path="/cabinet/trailerlogs/:id" element={<FullTrailerLog />} />
          <Route path="/cabinet/cars" element={<Cars />} />
          <Route path="/cabinet/trailers" element={<Trailers />} />
          <Route path="/cabinet/users" element={<Users />} />
          <Route path="*" element={<NoMatch />} />
          <Route path="/cars/:id" element={<Car />} />
          <Route path="/trailers/:id" element={<Trailer />} />
        </>
      ) : (
        <>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<NoMatch err='ви не авторизовані' />} />
        </>
      )}
      </Routes>
  </Container>  
  )
}
