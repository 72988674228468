import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';

export const fetchTrailer = createAsyncThunk('trailers/fetchTrailer', async(params) =>{
    const {data} = await axios.post('/manage/add-trailer', params)
    return data;
})

export const fetchTrailers = createAsyncThunk('trailers/fetchTrailers', async(params) =>{
  const {data} = await axios.get('/cabinet/trailers', params)
  return data;
})

export const fetchFreeTrailers = createAsyncThunk('trailers/fetchFreeTrailers', async(params) =>{
  const {data} = await axios.get('/cabinet/free-trailers', params)
  return data;
})

export const fetchTrailerDetails = createAsyncThunk('trailers/fetchTrailerDetails', async ()=>{
    const {data}= await axios.get('/trailers/${trailerId}');
    return data;
})

  const initialState = {
    trailers: {
      data: [],
      status: 'loading',
    },
  };


const trailerSlice = createSlice({
    name:'trailers',
    initialState,
    reducers:{},
    extraReducers:{
        [fetchTrailer.pending]: (state) => {
            state.status = 'loading';
            state.data = null;
        },
        [fetchTrailer.fulfilled]: (state, action) => {
            state.status = 'loaded';
            state.data = action.payload;
        },
        [fetchTrailer.rejected]: (state) => {
            state.status = 'error';
            state.data = null;
        },
        [fetchTrailers.pending]: (state, action) => {
          state.status = 'loading';
          state.data = null;
        },
        [fetchTrailers.fulfilled]: (state, action) => {
            state.status = 'loaded';
            state.data = action.payload;
          },
        [fetchTrailers.rejected]: (state, action) => {
            state.status = 'error';
            state.data = null;
          },
        [fetchFreeTrailers.pending]: (state, action) => {
          state.status = 'loading';
          state.data = null;
        },
        [fetchFreeTrailers.fulfilled]: (state, action) => {
            state.status = 'loaded';
            state.data = action.payload;
          },
        [fetchFreeTrailers.rejected]: (state, action) => {
            state.status = 'error';
            state.data = null;
          },

        [fetchTrailerDetails.pending]: (state, action) => {
            state.status = 'loading';
            state.data = null;
          },

        [fetchTrailerDetails.fulfilled]: (state, action) => {
            state.status = 'loaded';
            state.data = action.payload;
          },
        [fetchTrailerDetails.rejected]: (state, action) => {
            state.status = 'error';
            state.data = null;
          },
    }
})

export const trailerReducer = trailerSlice.reducer;
// export const getTrailerInformation = state=>(state.trailersTrailer.trailer);