import React from 'react';
import { TextField, Box, Button, Typography, Radio, RadioGroup, FormControlLabel, FormControl, Grid } from '@mui/material';

import 'easymde/dist/easymde.min.css';
import styles from './AddPost.module.scss';
import { useSelector } from 'react-redux';
import { Navigate } from "react-router";
import { selectTrailer, checkRole, selectCompany, checkTrailer } from '../../redux/slices/auth';
import { Link, useParams } from 'react-router-dom';
import axios from '../../axios';
import { ImagesManyPreview } from "../../utils/ImagePreview";
import { FormProvider } from '../../components/Carlog/carlogComponents/FormContext';
 
export const AddPostTrailer = () => { 
  const hasTrailer = useSelector(checkTrailer);
  const [isLoading, setLoading]= React.useState(true);
  const [text, setText] = React.useState('');
  const [urgency, setUrgency] = React.useState('');
  const [problemPhotos, setProblemPhotos] =React.useState([]);
  const [isNextButtonDisabled, setNextButtonDisabled] = React.useState(true); 
  const [data, setData] = React.useState(null);
  const [isFinished, setFinished ] = React.useState(false);
  const trailer = useSelector(selectTrailer);
  const company = useSelector(selectCompany);
  const field = 'problem';  
  const role = useSelector(checkRole);
  const {id} = useParams();


  const isFormValid = () => {
    return problemPhotos.length>0 && text && urgency;
  }

  const handleChangeImagesUrls = (urls, inputName) => {  
    switch (inputName) {
      case 'problem':
        setProblemPhotos(urls);
        break;
      default:
        break;   
    }
  }

  const onSubmit = async()=>{
    try {
      const fields ={
        text,
        urgency,
        problem:problemPhotos,
      }
      if(role==='manager'||role==='admin'||role==='mechanic'){
        fields.company = data.company;
        const{post} = await axios.post(`/add-post/trailer/${id}`, fields)
      }else{
        fields.company = company;
        const{post} = await axios.post('/cabinet/posts/trailer', fields)
      }     

      setFinished(true);
    } catch (err) {
      console.error(err);
      alert('не вдалося додати поломку. заповніть всі поля');
    }
  }

  function refreshPage() {
    window.location.reload(false);
  }

  React.useEffect(() => {
    if(role==='manager'||role==='admin'||role==='mechanic'){
      axios
      .get(`/trailers/${id}`)
      .then((res) => {
        setData(res.data.trailer);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        alert('помилка при отримання інфо');
      });
    }else{
      setData(trailer);
      setLoading(false);
    } 
    setNextButtonDisabled(!isFormValid());
  }, [problemPhotos, text, urgency]);

  if ((!hasTrailer&&role==='driver')||company==='Marshal Trans'){
    return <Navigate to="/home" />  
  }

  if (isLoading) {
    return <p>Loading...</p>
  }

  const Finish = ({data, slug})=>{
    return (
      <Box className='text-container'>
      <Typography classes={{root: styles.title}} variant="h2">
        Поломка успіно додана
      </Typography>
      <Typography classes={{root: styles.subtitle}} variant="p">
        {data.type+' '+data.trailerNumber}
      </Typography>
      <br/>
      <br/>
        <Box className={styles.buttons} 
            sx={{
              flexWrap: 'wrap'
            }}
        >
          <Button className='button' onClick={refreshPage} size="large" variant="contained" >
                додати ще
          </Button>
          <Link to={slug}>
            <Button className='button' variant="outlined" size="large" fullWidth={true}>повернутися</Button>
          </Link>
        </Box> 
      </Box>  
    )
  }

  return (
    <FormProvider>
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={10} md={10} lg={8} xl={8} className='main-container'> 
      {isFinished?<Finish data={data} slug={role==='driver'?'/home':'/cabinet/trailers'} /> :
      <>
        <Box className='text-container'>
          <Typography classes={{root: styles.title}} variant="h2">
            Що сталося з причепом
          </Typography>
          <Typography classes={{root: styles.subtitle}} variant="p">
            {data.type+' '+data.trailerNumber}
          </Typography>
        </Box>
        <TextField
          hiddenLabel          
          variant="outlined"
          placeholder="Опишіть поломку"
          value={text}
          onChange={ (e)=> setText(e.target.value)}
          fullWidth    
          multiline
          maxRows={2}      
        />
        <br />
        <br />
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={ (e)=> setUrgency(e.target.value)}
          className='rdButtons'
        >
          <FormControlLabel value="неважливо" control={<Radio />} label="неважливо" />
          <FormControlLabel value="важливо" control={<Radio />} label="важливо" />
          <FormControlLabel value="терміново" control={<Radio />} label="терміново" />
        </RadioGroup>
      </FormControl>
      
        <br />
        <br />
        <ImagesManyPreview
                number='можна вибрати до 4-х фото з галереї'
                inputName={field}
                labelText={field}
                onChangeImagesUrls={(urls) => handleChangeImagesUrls(urls, field)} 
          />  
        <br />
        <br />   

          <Box className={styles.buttons} 
          sx={{
            flexWrap: 'wrap'
          }}>
          <Button className='button' onClick={onSubmit} size="large" variant="contained" disabled={isNextButtonDisabled}>
            Зберегти і відправити
          </Button>
          <Link to="/">
            <Button className='button' variant="outlined" size="large" fullWidth={true}>Відміна</Button>
          </Link>
        </Box>
      </>
      }      
    </Grid>
    </Grid>
  </FormProvider>
  );
};
