export const headCells = [
    {
      id: 'type',
      numeric: false,
      label: 'Тип'
    },
    {
      id: 'company',
      numeric: false,
      label: 'Компанія', 
    },
    {
      id: 'carNumber',
      numeric: false,
      label: 'Автомобіль', 
    },
    // {
    //   id: 'brand',
    //   numeric: false,
    //   label: 'Бренд', 
    // },
    {
      id: 'fName',
      numeric: false,
      label: 'Водій',
    },
    {
      id: 'lookNote',
      numeric: false,
      label: 'Зауваження',
    },
    // {
    //   id: 'outerDefects',
    //   numeric: false,
    //   label: 'Пошкодж.Авто',
    // },
    {
      id: 'lookRating',
      numeric: true,
      label: 'Чистота',
    },
    {
      id: 'fTyreRating',
      numeric: true,
      label: 'П.Шини',
    },
    {
      id: 'bTyreRating',
      numeric: true,
      label: 'З.Шини',
    },
    // {
    //   id: 'tyreDefects',
    //   numeric: false,
    //   label: 'Деф.Шини',
    // },
    {
      id: 'tNote',
      numeric: false,
      label: 'Нотатка',
    },
    // {
    //   id: 'docs',
    //   numeric: false,
    //   label: 'Док.',
    // },
    // {
    //   id: 'inventory',
    //   numeric: false,
    //   label: 'Інстр.',
    // },
    {
      id: 'createdAt',
      numeric: true,
      label: 'Дата ств.',
    },  
  
  ];

export const trailerCells = [
  {
    id: 'type',
    numeric: false,
    label: 'Тип'
  },
  {
    id: 'company',
    numeric: false,
    label: 'Компанія', 
  },

  {
    id: 'trailerNumber',
    numeric: false,
    label: 'Номер', 
  },

  {
    id:'trailerType',
    numeric: false,
    label:'кузов',
  },
  {
    id: 'fName',
    numeric: false,
    label: 'Водій',
  },
  {
    id: 'lookNote',
    numeric: false,
    label: 'Зауваження',
  },
  {
    id: 'axis_1',
    numeric: false,
    label: '1 вісь',
  },
  {
    id: 'axis_2',
    numeric: false,
    label: '2 вісь',
  },
  {
    id: 'axis_3',
    numeric: false,
    label: '3 вісь',
  },
  // {
  //   id: 'tyreDefects',
  //   numeric: false,
  //   label: 'Деф.Шини',
  // },
  {
    id: 'tNote',
    numeric: false,
    label: 'Зауваження по шинам',
  },
  {
    id: 'zapasNumber',
    numeric: false,
    label: 'Запаски',
  },
  // {
  //   id: 'docs',
  //   numeric: false,
  //   label: 'Док.',
  // },
  {
    id: 'createdAt',
    numeric: false,
    label: 'Дата ств.',
  },
];