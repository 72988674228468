import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';


export const fetchUsers = createAsyncThunk('auth/fetchUsers',async(params) =>{
  const {data} = await axios.get('/manage/users', params)
  return data;
})

  const initialState = {
    users: {
      data: [],
      status: 'loading',
    },
  };


const userSlice = createSlice({
    name:'users',
    initialState,
    reducers:{},
    extraReducers:{
        [fetchUsers.pending]: (state, action) => {
          state.status = 'loading';
          state.data = null;
        },
        [fetchUsers.fulfilled]: (state, action) => {
            state.status = 'loaded';
            state.data = action.payload;
          },
        [fetchUsers.rejected]: (state, action) => {
            state.status = 'error';
            state.data = null;
          },
    }
})

export const usersReducer = userSlice.reducer;