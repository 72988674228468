import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';

export const fetchCar = createAsyncThunk('auth/fetchCar',async(params) =>{
    const {data} = await axios.post('/manage/add-car', params)
    return data;
})

export const fetchCars = createAsyncThunk('auth/fetchCars',async(params) =>{
  const {data} = await axios.get('/cabinet/cars', params)
  return data;
})

export const fetchFreeCars = createAsyncThunk('auth/fetchFreeCars',async(params) =>{
  const {data} = await axios.get('/cabinet/free-cars', params)
  return data;
})

export const fetchCarDetails = createAsyncThunk('auth/fetchCarDetails', async ()=>{
    const {data}= await axios.get('/cars/${carId}');
    return data;
})

  const initialState = {
    cars: {
      data: [],
      status: 'loading',
    },
  };


const carSlice = createSlice({
    name:'cars',
    initialState,
    reducers:{},
    extraReducers:{
        [fetchCar.pending]: (state) => {
            state.status = 'loading';
            state.data = null;
        },
        [fetchCar.fulfilled]: (state, action) => {
            state.status = 'loaded';
            state.data = action.payload;
        },
        [fetchCar.rejected]: (state) => {
            state.status = 'error';
            state.data = null;
        },
        [fetchCars.pending]: (state, action) => {
          state.status = 'loading';
          state.data = null;
        },
        [fetchCars.fulfilled]: (state, action) => {
            state.status = 'loaded';
            state.data = action.payload;
          },
        [fetchCars.rejected]: (state, action) => {
            state.status = 'error';
            state.data = null;
          },
        [fetchFreeCars.pending]: (state, action) => {
          state.status = 'loading';
          state.data = null;
        },
        [fetchFreeCars.fulfilled]: (state, action) => {
            state.status = 'loaded';
            state.data = action.payload;
          },
        [fetchFreeCars.rejected]: (state, action) => {
            state.status = 'error';
            state.data = null;
          },

        [fetchCarDetails.pending]: (state, action) => {
            state.status = 'loading';
            state.data = null;
          },

        [fetchCarDetails.fulfilled]: (state, action) => {
            state.status = 'loaded';
            state.data = action.payload;
          },
        [fetchCarDetails.rejected]: (state, action) => {
            state.status = 'error';
            state.data = null;
          },
    }
})

export const carReducer = carSlice.reducer;
// export const getCarInformation = state=>(state.authCar.car);